import React, {useEffect, useState} from 'react';
import {DFCForm} from "../../components/dfc/dfcForm";
import {ENV} from "../../configs";
import {AuthenticationSignupForm} from "../../view_forms/authentication/authenticationSignupForm";

export const AuthenticationSignupView = (props) => {
    const [referralCode, setReferralCode] = useState(null);
    const [createApplicantData, setCreateApplicantData] = useState({
        username: "",
        attributes: [
            {
                name: "email",
                value: "",
            },
            {
                name: "name",
                value: "",
            },
            {
                name: "given_name",
                value: "",
            },
            {
                name: "family_name",
                value: "",
            },
            {
                name: "picture",
                value: `https://applystart-fs-app-${ENV}.s3.amazonaws.com/defaults/profile-pictures/applicant.jpg`,
            },
        ],
        referralCode: "",
        linkReferral: false
    });

    const LoadData = () => {
        window.location.href = `/authentication/signup-complete/${createApplicantData.attributes[0].value}`;
    }

    const SetApplicantData = (applicant) => {
        if (applicant.referralCode) {
            setReferralCode(applicant.referralCode);
        }
        setCreateApplicantData(applicant);
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const referralCode = urlParams.get('ref');
        if (referralCode != null) {
            createApplicantData.referralCode = referralCode;
            createApplicantData.linkReferral = true;
        }
    }, []);

    return (
        <>
            <DFCForm className={"d-none"} data={createApplicantData} setData={SetApplicantData} isEditing={true}
                     formContent={AuthenticationSignupForm} editWithModal={true}
                     LoadData={LoadData} title={""}
                     saveDataUrl={`/applicant/index?referralCode=${referralCode ? referralCode : ""}`} saveHttpMethod={"post"}/>
        </>
    )
}