
import React, { useContext, useEffect, useState } from 'react'
import { UtilitiesContext } from '../../providers/utilitiesProvider';
import { PreloaderComponent } from "../../components/preloaderComponent";
import { DATA_VALUES, FILTER_TYPES } from '../../utils/constants';
import {DFCFilter, joinerOptions} from "../../components/dfc/dfcFilter";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {Col, Row} from "react-bootstrap";
import {DFCForm} from "../../components/dfc/dfcForm";
import {ProgramHeaderContent} from "../../view_forms/programs/programHeaderContent";
import {InstitutionProgramForm} from "../../view_forms/programs/institutionProgramForm";

export const ProgramsView = (props) => {
    const { GetDataFromServer } = useContext(UtilitiesContext);
    const { setDynamicViewTitleHTML } = useContext(UtilitiesContext);

    const [terms, setTerms] = useState(DATA_VALUES.LOADING);

    useEffect(() => {
        setDynamicViewTitleHTML("Search Programs")

        GetDataFromServer(`/term/index?showAll=false`, setTerms);
    }, []);


    const filterCategories =
        [
            {
                // need identifier and prefix expression
                name: "Programs",
                filterType: FILTER_TYPES.SEARCH,
                expression: "data.Name.Trim().ToLower().Contains({$value})"
            },
            {
                name: "Program Types",
                filterType: FILTER_TYPES.SELECT,
                options: ["Bachelors", "Certificate", "Diploma", "Masters", "Others"].map(x=>{return {label: x, value:x}}),
                expression: "data.EducationLevel=={$value}"
            },
            {
                // need identifier and prefix expression
                name: "Terms",
                filterType: FILTER_TYPES.SELECT,
                options: Array.isArray(terms) && terms.map(x => {return {label: x.name, value:x.name}}).concat({label: "Any", value: " "}),
                expression: `data.ProgramTerms.Any(pt=>pt.Term.Name.Contains({$value})${joinerOptions.and}pt.Open.Value)`
            },

        ]

    if (!Array.isArray(terms))
        return <PreloaderComponent />

    return (
        <ResponsiveContainerComponent>
            <DFCFilter
                title={"Search Programs"}
                getDataUrl={"/program/index?include=Institution"}
                categories={filterCategories}
                content={ProgramsFilteredView}
                takePerPage={20}
            />
        </ResponsiveContainerComponent>
    )
}

export const ProgramsFilteredView = (props) => {
    return (
        <Row>
            {
                props.data !== DATA_VALUES.NULL && props.data !== DATA_VALUES.LOADING &&
                props.data?.map((item, index) =>
                   <>
                       <Col key={index} xs={12} md={props.contentData?.cardSize??6}>
                           <DFCForm data={item}
                                    isEditable={props.isEditable} institutionId={props.institutionId}
                                    title={<ProgramHeaderContent item={item} />}
                                    formContent={InstitutionProgramForm}  className={'h-100'}
                           />

                       </Col>
                   </>
                )

            }
        </Row>
    )
}