import React, {useState} from "react";
import {Col} from "react-bootstrap";
import {DFCSelect} from "../../components/dfc/field/dfcSelect";
import {DFCText} from "../../components/dfc/field/dfcText";
import {DFCDateTime} from "../../components/dfc/field/dfcDateTime";
import {FIELDTYPES} from "../../utils/constants";
import {DFCDocuments} from "../../components/dfc/dfcDocuments";


export const ApplicantActivityForm  = (props)=>{
    const activityTypeOptions = [
        {label:"Internship", value:"Internship"},
        {label:"Volunteering", value:"Volunteering"},
        {label:"Awards", value:"Awards"},
        {label:"Extra Curricular Activity", value:"ECA"},
        {label:"Course", value:"Course"}
    ];
    const [activityDocumentCategory] = useState([{label:"Activity Document", value:"Activity_Document"}]);
    const _colSizeMd = 4;
    return (
       <>
           <Col xs={12} md={_colSizeMd}>
               <DFCSelect isEditing={props.isEditing}
                          accessor={`type`} title={"Activity Type"}
                          required
                          data={props.data} setData={props.setData} options={activityTypeOptions} />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCText title={"Name"} accessor={`name`} required
                        data={props.data} setData={props.setData}
                        isEditing={props.isEditing} />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCText title={"Position/Role"} accessor={`title`}
                        data={props.data} setData={props.setData}
                        isEditing={props.isEditing} />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCDateTime isEditing={props.isEditing}
                            accessor={"fromDate"} title={"Attended From"}
                            data={props.data} setData={props.setData}
               />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCDateTime isEditing={props.isEditing}
                            accessor={"toDate"} title={"Attended To"}
                            required
                            data={props.data} setData={props.setData}
               />
           </Col>


           <Col xs={12}>
               <DFCText title={"Notes"} accessor={"details"} type={FIELDTYPES.TEXTAREA}
                        placeholder={"https://www.uf.edu"}
                        data={props.data} setData={props.setData}
                        isEditing={props.isEditing} />
           </Col>

           {
               props.isAdding?
                   <div className={"badge badge-warning m-2"}>You may add attachments after you save</div>:
                   <Col xs={12}>
                       <DFCDocuments data={props.data}
                                     getListDataUrl={`/document/list/Activities/${props.data?.id}`}
                                     addDataUrl={`/document/list/Activities/${props.data?.id}`}
                                     deleteDataUrl={'/document/index'} documentCategories={activityDocumentCategory}
                                     isEditable={props.isEditable} maxAttachments={3} isEditing={props.isEditing}
                       />
                   </Col>
           }
       </>
    )
}