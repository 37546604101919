
import {Col, Row} from "react-bootstrap";
import React from "react";
import {DFCForm} from "../../components/dfc/dfcForm";
import {ApplicantContactInformationForm} from "../../view_forms/applicant/applicantContactInformationForm";

export const ApplicantContactInformationTab = (props)=>{
    return (
        <Row>
            <Col xs={12}>
                <DFCForm title="Contact Information"
                         isEditable={props.isEditable}
                         formContent={ApplicantContactInformationForm}
                         data={props.applicantData} LoadData={props.LoadApplicant} setData={props.setApplicantData}
                         saveDataUrl={`/applicant/index?Validator=ApplicantContactInformationValidator`}
                         saveHttpMethod={"put"} isEditing={props.isEditing}
                />
            </Col>
        </Row>
    )
}