import React, {useCallback, useContext, useEffect, useState} from 'react';
import {UtilitiesContext} from '../../providers/utilitiesProvider';
import Tippy from '@tippyjs/react';
import {Accordion, Card, Button, Row, Col, Modal, ListGroup, Badge, Form} from "react-bootstrap";
import {DATA_VALUES, TOAST_SETTINGS} from "../../utils/constants";
import {PreloaderComponent} from "../preloaderComponent";
import toast from "react-hot-toast";
import {DFCField} from "./field/dfcField";
import {DFCForm} from "./dfcForm";

export const DFCList = (props) => {
    const {getListDataUrl, instructions, title, addDataUrl, className, ...propsForForm} = props;

    const {GetDataFromServer, SaveDataToServer, SaveDataToServerWithoutConfirmation} = useContext(UtilitiesContext);
    const [data, setData] = useState(DATA_VALUES.LOADING);
    const [isAdding, setIsAdding] = useState(false);
    const [dataToAdd, setDataToAdd] = useState({});
    const [searchString, setSearchString] = useState("");
    const [pageStartIndex, setPageStartIndex] = useState(0);

    const takePerPage = props.takePerPage ?? 50;


    const LoadData = () => {
        if (getListDataUrl) {
            GetDataFromServer(getListDataUrl, setData, false);
        } else if (props.LoadData)
            props.LoadData();
        toast.dismiss(TOAST_SETTINGS.IDS.UNSAVEDCHANGES);
        setIsAdding(false);
        setDataToAdd({});
    };


    useEffect(() => {
        if (props.data && !getListDataUrl)
            setData(props.data);
        else LoadData();
    }, [props.getListDataUrl, props.data]);

    const AddData = () => {
        SaveDataToServerWithoutConfirmation(props.addDataUrl, "post", dataToAdd, null, false, LoadData);
    }

    const TransformedData = () => {
        if (!data || data === DATA_VALUES.LOADING)
            return data;
        if (!Array.isArray(data))
            return [];
         const filteredData = data.filter(props.filterFunction ?? (() => true))
                .sort(props.sortFunction ?? ((a,b) => a.name?.toUpperCase()<b.name?.toUpperCase()? -1:1 ))
            ?? [];
         if(props.searchable && searchString)
             return filteredData?.filter(x => !searchString || x[props.searchable]?.toString().toLowerCase().includes(searchString.toString().toLowerCase()));
         else return filteredData?.slice(pageStartIndex, pageStartIndex + takePerPage);
    }
    return (

        <> {/*Adding Modal*/

            props.addDataUrl &&
            <Modal
                show={isAdding} onHide={LoadData}
                size={props.modalSize ?? "md"}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <DFCField className={"text-medium text-truncate"} title={`Add ${props.title}`}
                                  instructions={props.addInstructions} renderChildren/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={"row"}>
                    <props.formContent data={dataToAdd} setData={setDataToAdd} formData={props.formData}
                                       isEditing={true} col={12} isAdding/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} className={"w-100 text-light"} onClick={AddData}>Save</Button>
                </Modal.Footer>
            </Modal>
        }

            {/*Search bar*/}
            {props.searchable &&
                <Form.Control placeholder={`Search by ${props.searchable}...`} value={searchString}
                              onChange={(e) => setSearchString(e.target.value)}/>
            }

            {/*----*/}
            <Accordion className={"w-100 my-2 overflow-auto overflow-x-hidden " + props.className}
                       style={{maxHeight: props.maxHeight}}>
                <Card.Header className={"bg-primary-light text-light py-1"}>
                    <Row>
                        <Col xs={8} className={"align-self-center"}>
                            {props.title &&
                                <h5 className="heading-2 d-inline">{props.title}
                                    <span
                                        className={"text-muted text-extra-small ml-1"}>({TransformedData()?.length})</span>
                                </h5>
                            }
                            {props.required && <span className="text-danger text-large fw-bold">*</span>}
                            {props.instructions &&
                                <Tippy content={props.instructions}>
                                    <i className="bi bi-info-square text-gray m-2 cursor-pointer"
                                       data-tooltip-id="tt-dfcForm"></i>
                                </Tippy>
                            }
                        </Col>
                        <Col xs={4} className={"text-right"}>
                            {
                                props.isEditable && addDataUrl &&
                                <Tippy content={"Add a Record"}>
                                    <button className="btn btn-outline-light btn-sm m-1" onClick={() => {
                                        setIsAdding(true);
                                    }}>
                                        <i className="bi bi-plus m-1"></i> ADD
                                    </button>
                                </Tippy>
                            }
                        </Col>
                    </Row>


                </Card.Header>
                <div className={"m-0 p-0 w-100"}>
                    {
                        TransformedData() === DATA_VALUES.LOADING ?
                            <PreloaderComponent small/>
                            :
                            TransformedData()?.length === 0 ?
                                <Badge className={"m-auto"} variant={"info"}>No data to show</Badge> :
                                TransformedData()?.map((item, index) =>
                                    <DFCForm key={index} index={index + 1} {...propsForForm} data={item}
                                             LoadData={LoadData} inList={true}
                                             listLength={TransformedData()?.length} editWithModal={true}
                                    />
                                )
                    }

                    {
                        data?.length > takePerPage &&
                        <Row className={"mx-auto mt-3"}>
                            <Col xs={5} className={"text-right"}>
                                {pageStartIndex > 0 &&
                                    <Button variant={"link"} size={"sm"}
                                        onClick={() => setPageStartIndex(pageStartIndex - takePerPage)}>Prev</Button>
                                }
                            </Col>
                            <Col xs={2} className={"text-center"}> Showing {pageStartIndex} - {pageStartIndex + takePerPage}</Col>
                            <Col xs={5}>
                                {data?.length > (pageStartIndex + takePerPage) &&
                                    <Button variant={"link"} size={"sm"}
                                        onClick={() => setPageStartIndex(pageStartIndex + takePerPage)}>Next</Button>
                                }
                            </Col>
                        </Row>
                    }
                </div>

            </Accordion></>
    )
}