import React, {useContext, useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {DFCSelect} from "../../components/dfc/field/dfcSelect";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {DFCText} from "../../components/dfc/field/dfcText";
import {DFCDateTime} from "../../components/dfc/field/dfcDateTime";
import {DFCAddress} from "../../components/dfc/presets/dfcAddress";
import {DFCPhone} from "../../components/dfc/presets/dfcPhone";

export const ApplicantAttendedInstitutionForm  = (props)=>{
    const {GetDataFromServer, ShowAlert} = useContext(UtilitiesContext);

    const [educationLevelEnumOptions, setEducationLevelEnumOptions] = useState();

    useEffect(() => {
        if(props.isEditing)
            GetDataFromServer('/system/enumchoices/EducationLevel', setEducationLevelEnumOptions);

    }, [GetDataFromServer, props.isEditing]);

    const _colSizeMd = 6;
    return (
       <>
           <Col xs={12} md={_colSizeMd}>
               <DFCText title={"School Name"} accessor={`name`} required
                        data={props.data} setData={props.setData}
                        isEditing={props.isEditing} />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCSelect isEditing={props.isEditing}
                          accessor={`educationLevel`} title={"Education Completed"}
                          required
                          data={props.data} setData={props.setData} options={educationLevelEnumOptions} />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCDateTime isEditing={props.isEditing}
                            accessor={"fromDate"} title={"Attended From"}
                            required
                            data={props.data} setData={props.setData}
               />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCDateTime isEditing={props.isEditing}
                            accessor={"toDate"} title={"Attended To"}
                            required
                            data={props.data} setData={props.setData}
               />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCPhone title={"School Phone Number "} accessor={"phone"}
                         isEditing={props.isEditing} required
                         data={props.data} setData={props.setData} />
           </Col>

           <Col xs={12}>
               <DFCAddress
                   title={"School Address"} accessor={"address"} required isEditing={props.isEditing}
                   data={props.data} setData={props.setData}
               />
           </Col>
       </>
    )
}