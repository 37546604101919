import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {AWSCONSTANTS, DATA_VALUES, VALID_VALUES} from "../../utils/constants";
import {PreloaderComponent} from "../../components/preloaderComponent";
import {DFCDocuments} from "../../components/dfc/dfcDocuments";
import {DFCList} from "../../components/dfc/dfcList";
import {ApplicationAdditionalInformationForm} from "../../view_forms/applications/applicationAdditionalInformationForm";
import {ApplicationMessageForm} from "../../view_forms/applications/applicationMessageForm";
import {AuthorizationContext} from "../../providers/authorizationProvider";
import {GetFormattedString} from "../../utils/helpers";
import {ENV} from "../../configs";

export const ApplicationStatusTab = (props) => {

    const {GetUserAttribute, currentUser} = useContext(AuthorizationContext);
    const [applicationDocumentCategory] = useState([{label:"Decision Document", value:"Decision_Document"}]);

    const IsAdmin = GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILETYPE) === VALID_VALUES.PROFILETYPES.ADMIN;

    const status = GetFormattedString(props.applicationData?.statusHistory?.sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))[0].status)?.toLowerCase();

    const IsStatusUnlocked = status !== VALID_VALUES.APPLICATIONSTATUSES.Cancel && status !== VALID_VALUES.APPLICATIONSTATUSES.Declined &&
        status !== VALID_VALUES.APPLICATIONSTATUSES.Accepted && status !== VALID_VALUES.APPLICATIONSTATUSES.Completed && status !== VALID_VALUES.APPLICATIONSTATUSES.Submitted_Applystart;


    const adminUserNames = ["applystart.tech"];

    if (!props.applicationData)
        return (<div>No Application</div>)

    if (props.applicationData === DATA_VALUES.LOADING)
        return <PreloaderComponent/>

    return (
        <Row>

            <Col xs={12}>
                <DFCList title={"Application Messages"} isEditable={IsAdmin && IsStatusUnlocked}
                         headerContent={(dProps) => <h6>{_.get(dProps.data, "name")}</h6>}
                         formContent={ApplicationMessageForm} sortFunction={(a,b)=>new Date(a.createdAt) < new Date(b.createdAt)?1:-1}
                         getListDataUrl={`/application/list/${props.applicationData?.id}/Messages`}
                         addDataUrl={`/application/list/${props.applicationData?.id}/Messages`}
                />

                <DFCList title={"Additional Information"} isEditable={IsStatusUnlocked}
                         headerContent={(dProps) =>
                             {
                                 const user = adminUserNames.includes(dProps.data?.createdBy?.trim())?"APPLYSTART": currentUser.username === dProps.data?.createdBy? "You":dProps.data?.createdBy;
                                 return (
                                     <div>
                                         <h6>{dProps.data?.name}</h6>
                                         <div className={"text-small text-muted"}>added by {user}</div>
                                     </div>
                                 )
                             }
                }
                         addInstructions={"Note: You may add attachments after saving the details"}
                         formContent={ApplicationAdditionalInformationForm} formData={{applicationId: props.applicationData?.id}}
                         getListDataUrl={`/application/list/${props.applicationData.id}/AdditionalInformation`}
                         deleteDataUrl={IsAdmin && `/application/list/${props.applicationData.id}/AdditionalInformation`}
                         addDataUrl={IsAdmin && `/application/list/${props.applicationData.id}/AdditionalInformation`}
                />

                {
                    (status === VALID_VALUES.APPLICATIONSTATUSES.Accepted || status === VALID_VALUES.APPLICATIONSTATUSES.Declined || status === VALID_VALUES.APPLICATIONSTATUSES.Completed || IsAdmin)
                    &&
                    <DFCDocuments independent instructionTitle={"Documents"}
                                  instructions={"Attach files such as transcripts, recommendation letters, resumes," +
                                      " or any documents that may affect your application."}
                                  getListDataUrl={`/document/list/Applications/${props.applicationData.id}`}
                                  addDataUrl={`/document/list/Applications/${props.applicationData.id}`}
                                  bucketName={`applystart-fs-application-${ENV}`} keyPrefix={props.applicationData.id}
                                  deleteDataUrl={'/document/index'} documentCategories={applicationDocumentCategory}
                                  isEditable={IsAdmin}
                                  isEditing={IsAdmin}
                    />
                }

            </Col>
        </Row>
    )
}