import React, {useContext, useEffect, useState} from "react";
import {AuthorizationContext} from "../../providers/authorizationProvider";
import {AWSCONSTANTS, DATA_VALUES, VALID_VALUES} from "../../utils/constants";
import {Badge, Button, Col, Modal, Row} from "react-bootstrap";
import {DFCText} from "../../components/dfc/field/dfcText";
import Tippy from "@tippyjs/react";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {PreloaderComponent} from "../../components/preloaderComponent";
import {AUTHENTICATION_URI} from "../../views/authentication/authenticationSigninPromptView";
import {Link} from "react-router-dom";

export const ProgramHeaderContent = (props) => {
    // console.log(props)
    const item = props.data??props.item;
    const {currentUser, GetUserAttribute} = useContext(AuthorizationContext);
    const {SaveDataToServerWithoutConfirmation, GetDataFromServer} = useContext(UtilitiesContext);
    const isAdmin = GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILETYPE) === VALID_VALUES.PROFILETYPES.ADMIN;

    const [showTermsForProgram, setShowTermsForProgram] = useState(false);
    const [applicationResponseData, setApplicationResponseData] = useState();

    const [programTerms, setProgramTerms] = useState();

    const ApplyForApplication = (program) => {
        if(currentUser === DATA_VALUES.NULL_USER)
            window.location.href = AUTHENTICATION_URI + `&state=${location.pathname}`;
        else
        setShowTermsForProgram(true);
    }

    const ApplyToProgramWithTerm = (termId) => {
        SaveDataToServerWithoutConfirmation(`/application/index/${item?.id}/${termId}`, 'post', null, setApplicationResponseData, false);
    }

    useEffect(() => {
        if (applicationResponseData?.id) {
            window.location.href = `/applications/${applicationResponseData.id}`
        }
    }, [applicationResponseData]);

    useEffect(() => {
        if(item?.id && !isAdmin){
            GetDataFromServer(`/program/list/${item.id}/ProgramTerms?include=Term`,setProgramTerms);
        }
    }, [item]);

    if(!item)
        return (<PreloaderComponent/>)

    return (
        <>
            <Modal show={showTermsForProgram}>
                <Modal.Header>
                    <Modal.Title>Select Application Term</Modal.Title>
                </Modal.Header>
                {(applicationResponseData === DATA_VALUES.LOADING) ?
                    <PreloaderComponent fullPage/>
                    :
                    <>
                        <Modal.Body>
                            {
                                <Col span xs={12} md={6}>
                                    <DFCText title={"Available Terms"} as={"textarea"} isEditing={false} isNotField/>
                                    {programTerms && programTerms !== DATA_VALUES.LOADING && programTerms?.map((item, index) =>
                                        <Row key={index}>
                                            <Col span xs={12} md={9}>
                                                <span>{item.term.name}</span>
                                            </Col>
                                            <Col span xs={12} md={3}>
                                                {
                                                    item.open == true ?
                                                        <Button variant={"success"} className={"float-end"} size={"sm"}
                                                                onClick={() => ApplyToProgramWithTerm(item.id)}>APPLY</Button>
                                                        :
                                                        <Tippy
                                                            content={"This program is not open for this term. Please check back later."}>
                                                            <Button className={"float-end"} disabled={true}
                                                                    variant={"tertiary"}
                                                                    size={"sm"}>APPLY</Button>
                                                        </Tippy>
                                                }
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="tertiary" onClick={() => setShowTermsForProgram(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                }
            </Modal>
            <Row className={"text-small bg-primary-light text-light cursor-pointer row font-weight-bold"}>
                <Col xs={9} className={"px-3"}>
                    <Link className={"text-light"} to={`/programs/${item.id}`}>
                    {/*<i className={`mr-1 bi bi-${item.open ? "check-circle" : "x-circle"}-fill text-${item.open ? "success" : "tertiary"}`}></i> {" "}*/}
                        {item.name} {item.deprecated || item.institution.deprecated || !item.institution.goLive ? <span className={"badge badge-secondary"}>not active</span> : <></>}
                    </Link>
                </Col>
                {
                    !isAdmin &&
                    <Col xs={3}>
                        {
                            !programTerms || programTerms === DATA_VALUES.LOADING ?
                                <PreloaderComponent small/>
                                :
                                !programTerms?.some((x) => x.open === true) ?
                                    <Tippy
                                        content={"This program is currently closed for application. Please check back later."}>
                                        <div className={"float-right"}>
                                            <Button disabled={true} variant={"tertiary"} size={"sm"}>APPLY</Button>
                                        </div>
                                    </Tippy>
                                    :
                                    <Button variant={"success"} className={"float-right"} size={"sm"}
                                            onClick={() => ApplyForApplication(item)} isAdmin={isAdmin}>APPLY</Button>

                        }

                    </Col>
                }
            </Row>
        </>
    )
}