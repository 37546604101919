import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UtilitiesContext } from '../../providers/utilitiesProvider';
import { useParams } from "react-router-dom";
import { AuthorizationContext } from '../../providers/authorizationProvider';
import {AWSCONSTANTS, DATA_VALUES, VALID_VALUES, VIEWTITLE_VALUES} from '../../utils/constants';
import { PreloaderComponent } from '../../components/preloaderComponent';
import {ApplicantPersonalInformationTab} from "./applicantPersonalInformationTab";
import {ApplicantAcademicsTab} from "./applicantAcademicsTab";
import {ApplicantDocumentTab} from "./applicantDocumentTab";
import {ApplicantFamilyTab} from "./applicantFamilyTab";
import {Button, Col, Row} from "react-bootstrap";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {ApplicantContactInformationTab} from "./applicantContactInformationTab";
import Tippy from "@tippyjs/react";
import {UpdateProfileInstructions} from "../profile/updateProfileInstructions";
import {ROUTE_VALUES} from "../../configs";
import {ApplicantLanguagesTab} from "./applicantLanguagesTab";

export const ApplicantProfileSubView = ( props ) =>
{
    const params = useParams();
    const { GetUserAttribute } = useContext( AuthorizationContext );
    const { GetDataFromServer, setDynamicViewTitleHTML, dynamicViewTitleHTML } = useContext( UtilitiesContext );
    const [ applicantData, setApplicantData ] = useState( DATA_VALUES.LOADING );
    const [ applicantId, setApplicantId ] = useState( DATA_VALUES.NULL );

    const LoadApplicant = useCallback( () =>
    {
        if ( applicantId )
            GetDataFromServer( `/applicant/index/${ applicantId }`, setApplicantData );
    }, [ applicantId ] );

    useEffect( () =>
    {
        setApplicantId( props.applicantId ?? params.applicantId );
    }, [ props.applicantId, params.applicantId ] );


    useEffect( () =>
    {
        LoadApplicant();

    }, [ applicantId ] );


    useEffect( () =>
    {
        const shouldUpdatePageTitle = !dynamicViewTitleHTML?.props?.children.includes(' | Application ID: ');
        if ( applicantData && applicantData !== DATA_VALUES.NULL && applicantData !== DATA_VALUES.LOADING && shouldUpdatePageTitle )
            setDynamicViewTitleHTML(`${ applicantData.name } | Applicant ID: ${ applicantData.displayIndex }`);

    }, [ setDynamicViewTitleHTML, applicantData, GetUserAttribute ] );

    const isApplicant = applicantId && GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILE) === applicantId;

    if ( applicantData === DATA_VALUES.FAILED )
        return (
            <div className="row mt-5 text-center container w-100">
                <h3 className="badge bg-danger">Failed to retrieve Applicant</h3>
            </div>
    )

    return (
        applicantData === DATA_VALUES.LOADING ?
            <PreloaderComponent />
            :
            <ResponsiveContainerComponent className={"pt-2"}>
                {
                    isApplicant && !props.isSnapshot &&
                   <>
                       <Row className={"sticky-top top-navsize p-1"}>
                           <Col className={"ml-auto text-right"}>
                               <Tippy content={<UpdateProfileInstructions/>} allowHTML>
                                   <a className={"btn btn-sm btn-danger"} href={ROUTE_VALUES.APPLICANT_UPDATEPROFILE}>
                                       Update Profile
                                   </a>
                               </Tippy>
                           </Col>
                       </Row>

                       <div className={"text-small bg-warning p-2 rounded-bottom"}>
                           Keep your profile updated. You must fill out and complete your profile to apply to institutions.
                           Any institutions you apply to, will receive information in your profile that you update <strong>
                           before you submitted the application.
                       </strong>
                       </div>
                   </>
                }

                <ApplicantPersonalInformationTab applicantData={applicantData}/>
                <hr/>
                <ApplicantLanguagesTab applicantData={applicantData}/>
                <hr/>
                <ApplicantAcademicsTab applicantData={applicantData}/>
                <hr/>
                <ApplicantFamilyTab applicantData={applicantData}/>
                <hr/>
                <ApplicantDocumentTab applicantData={applicantData}/>
                <hr/>
                <ApplicantContactInformationTab applicantData={applicantData}/>

            </ResponsiveContainerComponent>



     
    )
}