import React from "react";
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { ViewComponent } from './components/viewComponent';
import { AWS_CONFIGS, ROUTE_VALUES} from './configs';
import { AuthenticationSigninResultView } from './views/authentication/authenticationSigninResultView';
import { InstitutionsView } from './views/institutions/institutionsView.js';
import { NotFoundView } from './views/notFoundView';
import { AuthenticationSignoutView } from './views/authentication/authenticationSignoutView';
import { ProfileSelectorView } from './views/profile/profileSelectorView';
import { ReferralView } from './views/account/referralView';
import { ApplicationsView } from './views/applications/applicationsView';
import {AuthenticationSignupView} from "./views/authentication/authenticationSignupView";
import {ApplicationView} from "./views/applications/applicationView";
import {AdminCreateInstitutionView} from "./views/admin/adminCreateInstitutionView";
import {InstitutionView} from "./views/institutions/institutionView";
import {AdminCreateInfluencersView} from "./views/admin/adminCreateInfluencersView";
import {AuthenticationSignupCompleteView} from "./views/authentication/authenticationSignupCompleteView";
import {ApplicantUpdateProfileView} from "./views/applicant/applicantUpdateProfileView";
import {ApplicantProfileSubView} from "./views/applicant/applicantProfileSubView";
import {SignupRedirection} from "./views/redirect_oldroutes/SignupRedirection";
import {AdminManageTermsView } from "./views/admin/adminManageTermsView";
import {AdminApplicantsView} from "./views/admin/adminApplicantsView";
import {AdminCountriesView} from "./views/admin/adminCountriesView";
import {NotificationsView} from "./views/account/notificationsView";
import { ProgramsView } from './views/programs/programsView';
import {ProgramView} from "./views/programs/programView";
import {ApplyView} from "./views/applications/applyView";

class ApplystartRoute
{
    constructor ( path, component, requireAuthorization = true, hideNavbar=false)
    {
        this.path = path; this.component = component; this.requireAuthorization = requireAuthorization; this.hideNavbar=hideNavbar;
    }
}

export const Routes = () => {

    const applystart_routes = [
        new ApplystartRoute( ROUTE_VALUES.APP_HOMEPAGE, InstitutionsView, false ),
        new ApplystartRoute( AWS_CONFIGS.COGNITO_LOGINRESULT_URL, AuthenticationSigninResultView, false, true ),
        new ApplystartRoute( AWS_CONFIGS.COGNITO_LOGOUT_URL, AuthenticationSignoutView, false, true ),
        new ApplystartRoute( ROUTE_VALUES.SIGNUP, AuthenticationSignupView, false, true ),
        new ApplystartRoute( "/authentication/signup-complete/:email?", AuthenticationSignupCompleteView, false, true ),
        new ApplystartRoute( ROUTE_VALUES.NOTIFICATIONS, NotificationsView, true, false ),
        new ApplystartRoute( "/account/refer", ReferralView, true ),
        new ApplystartRoute( "/profile", ProfileSelectorView, true ),
        new ApplystartRoute( "/profile/applicant/:applicantId", ApplicantProfileSubView, true ),
        new ApplystartRoute( ROUTE_VALUES.APPLICANT_UPDATEPROFILE, ApplicantUpdateProfileView, true ),
        new ApplystartRoute( "/institutions/:id", InstitutionView, false ),
        new ApplystartRoute(ROUTE_VALUES.PROGRAMS_PAGE, ProgramsView, false),
        new ApplystartRoute( "/programs/:id", ProgramView, false ),
        new ApplystartRoute( "/apply/:programId/:termId", ApplyView, true ),
        new ApplystartRoute( "/applications", ApplicationsView, true ),
        new ApplystartRoute( "/applications/:id", ApplicationView, true ),
        new ApplystartRoute( "/admin/applicants", AdminApplicantsView, true ),
        new ApplystartRoute( "/admin/countries", AdminCountriesView, true ),
        new ApplystartRoute( "/admin/create-institution", AdminCreateInstitutionView, true ),
        new ApplystartRoute("/admin/create-influencer", AdminCreateInfluencersView, true),
        new ApplystartRoute("/admin/manage-terms", AdminManageTermsView, true),

    //     old route redirection
        new ApplystartRoute( "/external/applicant-signup", SignupRedirection, false ),

    ];

    return (
        <BrowserRouter>
            <Switch>
                {
                    applystart_routes.map( ( item, index ) =>
                        <Route exact path={item.path} key={index}>
                            <ViewComponent {...item} />
                        </Route>
                    )
                }
                <Route path={"*"}>
                    <ViewComponent title="" component={NotFoundView} hideNavigation={true} />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}