import React, {useContext} from 'react';
import {AuthorizationContext} from '../providers/authorizationProvider';
import {DATA_VALUES} from '../utils/constants';
import {AuthenticationSigninPromptView} from '../views/authentication/authenticationSigninPromptView';
import {NavbarComponent} from './navbar/navbarComponent';
import {FooterComponent} from "./footerComponent";
import { ROUTE_VALUES} from "../configs";


export const ViewComponent = (props) => {
    const {currentUser} = useContext(AuthorizationContext);

    return (<div className="applystart-view position-relative ">

            {
                props.requireAuthorization && currentUser === DATA_VALUES.NULL_USER ?
                    <div className={"page-component"}>
                        <AuthenticationSigninPromptView path={props.path === ROUTE_VALUES.APP_HOMEPAGE ? null : props.path}/>

                    </div>
                    :
                    <>
                        {!props.hideNavbar && <NavbarComponent/>}
                        <div className={"page-component "+(props.hideNavbar?"":"nav-enabled")}>
                            <props.component/>
                        </div>
                    </>
            }


            <FooterComponent/>

    </div>)

}