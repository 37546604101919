import React, { useContext, useEffect, useState } from 'react'
import { UtilitiesContext } from '../../providers/utilitiesProvider';
import { DATA_VALUES } from '../../utils/constants';
import { DFCSelect } from '../../components/dfc/field/dfcSelect';
import {Col} from "react-bootstrap";

export const ApplicantLanguageForm = (props ) =>
{
    const [ languageEnumOptions, setLanguageEnumOptions ] = useState( DATA_VALUES.EMPTY_ARRAY );
    const { GetDataFromServer } = useContext( UtilitiesContext );


    useEffect( () =>
    {
        if(props.isEditing && languageEnumOptions === DATA_VALUES.EMPTY_ARRAY)
            GetDataFromServer( '/system/Languages', setLanguageEnumOptions );

    }, [ GetDataFromServer ] );

    return (
        <Col xs={12}>
            <DFCSelect isEditing={props.isEditing}
                       accessor={"language"} title={"Language"}  optionLabelAccessor={"name"} optionValueAccessor={0}
                       required
                       data={props.data} setData={props.setData} options={languageEnumOptions} />
        </Col>
    );
}