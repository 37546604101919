export const COOKIE_KEYS = {
    access_token: "access_token"
};

export const AWSCONSTANTS = {
    ATTRIBUTENAME_PROFILETYPE: "custom:profile_type",
    ATTRIBUTENAME_PROFILE: "profile"
}

export const VIEWTITLE_VALUES = {
    EMPTY: "-",
    UNSET: "...",
}


export const DATA_VALUES = {
    NULL_USER: null,
    NULL: null,
    NODATA_HTML: <span className={"badge text-extra-small text-muted"}>no_value</span>,
    FAILED: "no_data",
    LOADING: "data_loading",
    EMPTY_ARRAY: [],
    EMPTY_STRING: "",
    EMPTY_FUNCTION: () => {
    }
}

export const VALID_VALUES = {
    PROFILETYPES: {
        INSTITUTION: "institution",
        APPLICANT: "applicant",
        ADMIN: "business_admin"
    },
    MESSAGETYPES: {
        WARNING: "Warning",
        ERROR: "Error"
    },
    ALLOWEDRESPONSETYPES: {
        Paragraph: "Paragraph",
        No_Response: "No_Response_Required",
        Attachments: "Attachments"
    },
    APPLICATIONSTATUSES: {
        Cancel: "cancel",
        Declined: "declined",
        Accepted: "accepted",
        Completed: "completed",
        PaymentPending: "payment pending",
        ReviewAndSubmit: "review and submit",
        InProgress: "in progress",
        Submitted_Applystart: "submitted to applystart"
    }
}

export const FIELDTYPES = {
    EMAIL: "EMAIL",
    PHONE: "PHONE",
    WEBSITE: "WEBSITE",
    TEXTAREA: "textarea"
}

export const TOAST_SETTINGS = {
    MESSAGES: {
        UNSAVEDCHANGES: <><i className="bi bi-exclamation-circle-fill me-1 text-info"></i> You have unsaved changes!</>,
    },
    IDS: {
        AUTHORIZATION: "auth_toastId",
        REQUEST: "request_toastId",
        FILE: "file_toastId",
        UNSAVEDCHANGES: "unsavedChanges_toastId",
        WARNINGS: "warning_toastId"
    },
    DURATIONS: {
        UNSAVEDCHANGES: 1000000
    }
}

export const FILTER_TYPES = {
    CHECKBOX: "Checkbox",
    SELECT: "Select",
    SEARCH: "Search"
}
