import React from 'react';
import {Card, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {AWS_CONFIGS, ENV} from "../../configs";
import {DFCText} from "../../components/dfc/field/dfcText";
import {FIELDTYPES} from "../../utils/constants";

export const AuthenticationSignupForm = (props)=>{

    const SetName = ()=>{
        const _firstName = props.data.attributes[2].value;
        const _lastName = props.data.attributes[3].value;

        const _tempData = {...props.data}
        _tempData.attributes[1].value = _firstName+" "+_lastName;
        props.setData(_tempData);
    }

    return (<>

        <Col xs={12}>
            <Card.Body className={"p-4 text-center"}>
                <img src={require("../../graphics/logo/applystart-logo-black.svg").default} width={200}/>
                <img className={"d-block m-auto"} src={`https://applystart-fs-app-${ENV}.s3.amazonaws.com/defaults/profile-pictures/applicant.jpg`} width={60}/>
                <h4>Welcome {props.data.attributes[1].value}!</h4>
                <h5> You are about to complete your request to join Applystart! We
                    are thrilled to have you here!
                    Please follow the steps to complete your profile! </h5>

                <div className={"text-left shadow p-2 m-4"}>
                    <h5 className={"text-center"}> Instructions </h5>
                    <ol>
                        <li>Fill out the form with relevant information and click Save.</li>
                        <li>Check Email: Upon sign-up, you will receive an email containing your username and temporary password. Please check the spam/promotions folder if you don't see them.</li>
                        <li>Sign In: Use the temporary credentials provided in the email to sign in to your Applystart account.</li>
                    </ol>
                </div>
                <h5>If you already have an account, please <Link to={AWS_CONFIGS.COGNITO_LOGOUT_URL}>Sign In</Link></h5>

            </Card.Body>

        </Col>


        <Col xs={12} md={5}>
            <DFCText title={"Username"} accessor={`username`} instructions={"Username may not be changed once created"}
                     required data={props.data} setData={props.setData}
                     isEditing={props.isEditing} />
        </Col>

        <Col xs={12} md={7}>
            <DFCText title={"Email"} accessor={`attributes[0].value`} instructions={"Email may not be changed once created"}
                     required data={props.data} setData={props.setData}
                     isEditing={props.isEditing} type={FIELDTYPES.EMAIL}/>
        </Col>

        <Col xs={12} md={6}>
            <DFCText title={"First Name"} accessor={`attributes[2].value`}
                     required data={props.data} setData={props.setData} onChange={SetName}
                     isEditing={props.isEditing} />
        </Col>


        <Col xs={12} md={6}>
            <DFCText title={"Last Name"} accessor={`attributes[3].value`}
                     required data={props.data} setData={props.setData} onChange={SetName}
                     isEditing={props.isEditing} />
        </Col>

        <Col xs={12} md={5}>
            <DFCText title={"Referral Code (Optional)"} accessor={`referralCode`} instructions={"If someone has referred you, enter the referral code here"}
                     data={props.data} setData={props.setData}
                     isEditing={!props.data?.linkReferral == true && props.isEditing} />
        </Col>

    </>)
}