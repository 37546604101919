
import React, { useContext, useEffect, useState } from 'react'
import { DFCForm } from '../../components/dfc/dfcForm';
import { ReferralForm } from '../../view_forms/account/referralForm';
import { UtilitiesContext } from '../../providers/utilitiesProvider';
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {useHistory, useLocation} from "react-router-dom";
import {ROUTE_VALUES} from "../../configs";

export const ReferralView = ( props ) =>
{
    const _blankEmailData = { email: "" };
    const { setDynamicViewTitleHTML } = useContext( UtilitiesContext );
    const [ referralData, setReferralData ] = useState( _blankEmailData );

    const browserHistory = useHistory();

    const HandleComplete = () =>
    {
        browserHistory.push(ROUTE_VALUES.APP_HOMEPAGE);
    }

    useEffect( () =>
    {
        setDynamicViewTitleHTML( "Refer a friend to Applystart" )
    },[]);

    return (
        <ResponsiveContainerComponent>
            <DFCForm className={"d-none"} title={"Refer a friend to Applystart"}
                     data={referralData} setData={setReferralData}
                     LoadData={HandleComplete}
                     isEditing={true}
                     saveHttpMethod={'post'} saveDataUrl={`/applicant/referral`}
                     formContent={ReferralForm} editWithModal={true}
            />
        </ResponsiveContainerComponent>
    )
}