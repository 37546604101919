import {DFCList} from "../../components/dfc/dfcList";
import React, {useContext, useEffect} from "react";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {AdminCountriesFormContent} from "../../view_forms/admin/adminCountriesFormContent";
export const AdminCountriesView = () => {
    const { setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    useEffect(() => {
        setDynamicViewTitleHTML("Countries List");
    }, []);

    return (
        <ResponsiveContainerComponent>
            <DFCList title={"Supported Countries"} hideBody={true}
                     getListDataUrl={"/system/countries"}
                     editDataUrl={"/system/countries"} isEditable={true}
                     headerContent={AdminCountriesFormContent} formContent={AdminCountriesFormContent}
            />
        </ResponsiveContainerComponent>
    )
}