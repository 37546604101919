import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { DFCField } from './dfcField';
import { FormatSelectOptionFromValue, GetFormattedString, InfoToastContent } from '../../../utils/helpers';
import {DATA_VALUES, TOAST_SETTINGS, VALID_VALUES,} from '../../../utils/constants';

export const DFCSelect = ( props ) =>
{
    const [ options, setOptions ] = useState( DATA_VALUES.LOADING );
    const [currentValue, setCurrentValue] = useState(DATA_VALUES.NULL);

    useEffect(() => {
        if(!props.data)
        {
            setCurrentValue(undefined);
            return;
        }
        if(props.data !== DATA_VALUES.LOADING){
            let _value = _.get( props.data, props.accessor );
            let _label = _.get( props.data, props.accessor+"."+props.optionLabelAccessor );

            //if nothing is selected, choose the first available option, and set the value in the data ballot
            if(!_value && options?.length>0 && !props.nullable){
                _label = options[0].label;
                _value = options[0].value;
                const _dataValue = _.get(props.data, props.accessor);
                if(_dataValue !== _value){
                    let _tempData = {...props.data};
                    _.set( _tempData, props.accessor, _value );
                    props.setData( _tempData );
                }
            }
            setCurrentValue(
                FormatSelectOptionFromValue( _value, _label ));
        }
    }, [props.data, props.accessor, options]);

    useEffect( () =>
    {
        if ( !props.options )
            return;

        if ( props.options !== DATA_VALUES.LOADING )
        {
            const _tempOptions = [];
            props.options && props.options.map( ( option ) =>
            {
                const _value = props.optionValueAccessor === 0 ? option : option[ props.optionValueAccessor ?? "value" ];
                _tempOptions.push( {
                    label: (props.optionLabelAccessor? option[ props.optionLabelAccessor ]: (option.label??_value))?.replaceAll("_", " "),
                    value: _value
                } )
            } );

            setOptions( _tempOptions );
        }

        else if (props.options === DATA_VALUES.LOADING)
            setOptions(DATA_VALUES.LOADING);

    }, [ props.options ] );

    const HandleChange = ( new_value ) =>
    {
        if ( new_value )
        {
            setCurrentValue(new_value)
            let _tempData = {...props.data};
            _.set( _tempData, props.accessor, new_value.value );
            props.setData( _tempData );
            toast( TOAST_SETTINGS.MESSAGES.UNSAVEDCHANGES, { id: TOAST_SETTINGS.IDS.UNSAVEDCHANGES, duration: TOAST_SETTINGS.DURATIONS.UNSAVEDCHANGES } );
        }
    }

    return (
        <DFCField {...props} value={currentValue?.label} >
            {props.creatable ?
                <CreatableSelect
                    isLoading={options === DATA_VALUES.LOADING} isDisabled={!props.isEditing} options={options !== DATA_VALUES.LOADING ? options : []} onChange={HandleChange}
                    placeholder={props.placeholder ?? "Please select.."} value={currentValue} formatCreateLabel={(inputValue => `Custom: ${inputValue}`)}
               /> :
                <Select isLoading={options === DATA_VALUES.LOADING} isDisabled={!props.isEditing} options={options !== DATA_VALUES.LOADING ? options : []} onChange={HandleChange}
                        placeholder={props.placeholder ?? "Please select.."} value={currentValue}/>
            }
        </DFCField>
    );

}