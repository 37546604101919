import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Redirect, useLocation } from "react-router-dom";
import {APP_DOMAIN, AWS_CONFIGS, ROUTE_VALUES} from '../../configs';
import { AuthorizationContext } from '../../providers/authorizationProvider';
import { COOKIE_KEYS, DATA_VALUES, VIEWTITLE_VALUES, } from '../../utils/constants';
import { UtilitiesContext } from '../../providers/utilitiesProvider';
import {AuthenticationTemplate} from "./authenticationTemplate";
import {PreloaderComponent} from "../../components/preloaderComponent";

function useQuery ()
{
    const { search } = useLocation();
    return React.useMemo( () => new URLSearchParams( search ), [ search ] );
}

export const AuthenticationSigninResultView = () =>
{
    const location = useLocation();
    const queries = useQuery();

    const { currentUser, GetUserAttribute, setCookie, InternalLogout } = useContext( AuthorizationContext );
    const { setDynamicViewTitleHTML } = useContext( UtilitiesContext );

    const [ redirectPath, setRedirectPath ] = useState( AWS_CONFIGS.COGNITO_LOGIN_REDIRECTURI );

    const parseCookie = useCallback( () =>
    {
        if ( currentUser !== DATA_VALUES.NULL_USER )
            return;
        //fix routing to parse queries
        if ( location.hash )
            window.location.replace( window.location.href.replace( '#', '?' ) );
        const parsed_access_token = queries.get( COOKIE_KEYS.access_token );
        const state = queries.get( 'state' )?.split( ',' );
        setRedirectPath( state ? state[ 0 ] : APP_DOMAIN );

        if ( parsed_access_token != null )
            setCookie( COOKIE_KEYS.access_token, parsed_access_token, {path: ROUTE_VALUES.APP_HOMEPAGE} );

    }, [ queries, setCookie, location, currentUser ] );


    useEffect( () =>
    {
        setDynamicViewTitleHTML( VIEWTITLE_VALUES.EMPTY );
    }, [ setDynamicViewTitleHTML ] );


    useEffect( () =>
    {
        InternalLogout(true);
        parseCookie();

    }, [] );

    // //check if login completed
    if ( currentUser && GetUserAttribute( "name" ) != null && redirectPath )
        return ( <Redirect to={redirectPath} /> );

    return (
        <AuthenticationTemplate title={"Logging In to"}>
            <PreloaderComponent small/>
        </AuthenticationTemplate>
    )

}