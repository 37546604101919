import { HOST_CONFIGS } from "../configs";
import {DATA_VALUES, TOAST_SETTINGS, VALID_VALUES} from "./constants";
import {Button} from "react-bootstrap";
import {toast} from "react-hot-toast";
import React from "react";

export function AppendServerBaseUrl ( url )
{
    return `${ HOST_CONFIGS.BACKEND_HOST }${ url }`;
}

export function ResolveJSON ( json )
{
    if ( !json )
        return null;
    else json = JSON.stringify( json )
    var refMap = {};

    return JSON.parse( json, function ( key, value )
    {
        if ( key === '$id' )
        {
            refMap[ value ] = this;
            // return undefined so that the property is deleted
            return void ( 0 );
        }
        if ( value && value.$ref ) { return refMap[ value.$ref ]; }

        return value;
    } );
}

export function GenerateMessageContentForToast ( messageQueue, messageType = VALID_VALUES.MESSAGETYPES.WARNING )
{
    let icon = "bi-info-circle-fill text-info";
    if(messageType === VALID_VALUES.MESSAGETYPES.WARNING)
        icon = "bi-exclamation-circle-fill text-warning"
    else if (messageType === VALID_VALUES.MESSAGETYPES.ERROR)
        icon = "bi-x-circle-fill text-danger"


    return ( <div>
        <div className="font-weight-bold mb-2">{messageType} <i className={"bi "+icon}></i></div>

        <ul>
            {messageQueue.map( ( item ) =>
                <li dangerouslySetInnerHTML={{__html: item.shortMessage}}></li>
            )}
        </ul>

        {messageType === VALID_VALUES.MESSAGETYPES.ERROR &&
            <Button size={"sm"} className={"border-none m-auto"}
                    variant={"outline-tertiary"}
                    onClick={() => toast.dismiss(TOAST_SETTINGS.IDS.REQUEST)}>CLOSE</Button>
        }
    </div>);
}

export function InfoToastContent ( content ) {
    return <><i className="bi bi-exclamation-circle-fill m-1 text-info"></i> {content}</>
}

export function RedirectToLink( link ){
    window.location.href = link;
}

export function CleanseDataForServer ( data )
{
    if ( !data )
        return;
    data.applicant = null;
    data.institution = null;
    data.program = null;
    data.photos = null;
    data.modelValidationMessages = null;
    data.programTerms = null;
    return data;
}

export function GetFormattedString ( originalEnumValue )
{
    return originalEnumValue?.toString().replaceAll("_opbrac_","(").replaceAll("_clbrac_",")").replaceAll("_slash_","/").replaceAll( "_", " " );
}

export const FormatSelectOptionFromValue = ( value, label ) =>
{
    if(!value)
        return null;
    return { label: (label??value)?.replaceAll("_opbrac_","(").replaceAll("_clbrac_",")").replaceAll("_slash_","/").replaceAll("_", " "), value: value };
}

export const EscapeRegex = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}