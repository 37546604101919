import React, {useContext, useEffect, useState} from 'react';
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {DATA_VALUES, VALID_VALUES} from "../../utils/constants";
import {Link, useParams} from "react-router-dom";
import {PreloaderComponent} from "../../components/preloaderComponent";
import {DFCTab} from "../../components/dfc/dfcTab";
import {ApplicationApplicantTab} from "./applicationApplicantTab";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {Button, Card, Col, Row} from "react-bootstrap";
import {GetFormattedString} from "../../utils/helpers";
import {ApplicationStatusTab} from "./applicationStatusTab";
import {ApplicantPaymentInstructionsContent} from "../../view_forms/applications/applicantPaymentInstructionsContent";

export const ApplicationView = (props) => {
    const params = useParams();
    const {GetDataFromServer, SaveDataToServer, setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    const [applicationData, setApplicationData] = useState(DATA_VALUES.NULL);
    const [availableStatuses, setAvailableStatuses] = useState();
    const [showPaymentInstructions, setShowPaymentInstructions] = useState(true);

    const status = GetFormattedString(applicationData?.statusHistory?.sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))[0].status)?.toLowerCase();

    const LoadApplication = () => {
        if (params.id) {

            GetDataFromServer(`/application/index/${params.id}?include=ApplicantSnapshot,Program.Institution,ApplicationTerm.Term`, setApplicationData);
            GetDataFromServer(`/application/index/${params.id}`, setAvailableStatuses,null,null,"options");
        }
    };

    useEffect(() => {
        LoadApplication();
    }, [params.id]);

    useEffect(() => {
        if (applicationData) {
            setDynamicViewTitleHTML(
                <>
                    <Link className={"text-light text-decoration-underline"}
                          to={`/institutions/${applicationData.program?.institution.id}`}>{applicationData.program?.institution.name}</Link>
                    {" "} - {applicationData.applicantSnapshot?.name} | Application ID: {applicationData.displayIndex}
                </>
            );
        }
    }, [applicationData, setDynamicViewTitleHTML]);

    const LoadData = () => {
        window.location.reload();
    }

    const ChangeApplicationStatus = (status) => {
        SaveDataToServer(`/application/index/${applicationData?.id}/${status}`, "patch", null, null, false, LoadData);
    }

    if (applicationData === DATA_VALUES.FAILED)
        return (
            <div className="row mt-5 text-center w-100 container">
                <h3 className="badge bg-danger">Failed to retrieve Application</h3>
            </div>
        );


    if (applicationData === DATA_VALUES.LOADING)
        return <PreloaderComponent/>

    return (
        <ResponsiveContainerComponent>

            <Row className={"my-2 border-bottom py-4"}>
                <Col xs={12} md={4} className="">
                    <Button className="w-100 text-small disabled" disabled variant="info">Current Status: &nbsp;
                        {GetFormattedString(applicationData?.statusHistory?.sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))[0].status)}
                    </Button>
                </Col>

                <Col xs={12} md={4} className="ml-auto">
                    <Link to={`/programs/${applicationData?.program?.id}`}>
                        <Button className="w-100 text-small mb-2"
                                variant="info">Program: {applicationData?.program?.name}
                        </Button>
                    </Link>

                    <Button className="w-100 text-small"
                            variant="info">Term: &nbsp;
                        {applicationData?.applicationTerm?.term.name}
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={12}>
                    {
                        applicationData?.program &&
                        (status === VALID_VALUES.APPLICATIONSTATUSES.ReviewAndSubmit || status === VALID_VALUES.APPLICATIONSTATUSES.Submitted_Applystart)
                        &&
                        <>
                            {showPaymentInstructions &&
                                <ApplicantPaymentInstructionsContent applicationData={applicationData}/>
                            }
                                <div className={"text-center"}>
                                    {
                                        showPaymentInstructions?
                                            <Button variant={"link"} onClick={()=>setShowPaymentInstructions(false)}>Hide Payment Instructions</Button>:
                                            <Button variant={"link"} onClick={()=>setShowPaymentInstructions(true)}>Show Payment Instructions</Button>
                                    }
                                </div>
                        </>
                    }
                </Col>
            </Row>
            <Row className="mb-3 justify-content-start border-bottom pb-2">
                <Col xs={12}>
                    <label>Change Status To</label>
                </Col>
                <Col xs={12} className={"text-left"}>
                    {Array.isArray(availableStatuses) && availableStatuses?.map(item =>

                        <Button variant={"info"} className={"m-1"} onClick={() => ChangeApplicationStatus(item)}>
                            {GetFormattedString(item)}
                        </Button>
                    )}
                </Col>

            </Row>

            <Row>
                <Col xs={12} md={8}>
                    <DFCTab hideIndex>
                        <ApplicationApplicantTab title="Applicant Profile" applicationData={applicationData}
                                                 LoadApplication={LoadApplication}
                                                 setApplicationData={setApplicationData}/>

                        {
                            (status !== VALID_VALUES.APPLICATIONSTATUSES.InProgress && status !== VALID_VALUES.APPLICATIONSTATUSES.ReviewAndSubmit)
                            &&
                            <ApplicationStatusTab title="Application Updates" applicationData={applicationData}
                                                  LoadApplication={LoadApplication}
                                                  setApplicationData={setApplicationData}/>
                        }


                    </DFCTab>
                </Col>
                <Col xs={12} md={4}>
                    <Card>
                        <Card.Header as="h4" className="pt-3 pb-3 text-light font-weight-bold bg-dark">
                            History
                        </Card.Header>
                        <Card.Body className={"overflow-auto"} style={{height: "50vh"}}>
                            {
                                applicationData?.statusHistory?.sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))
                                    .map((item) =>
                                        <Row key={item.id}>
                                            <Col xs="auto"
                                                 className="sw-1 d-flex flex-column align-items-center position-relative ml-4">
                                                <div className="w-100 d-flex sh-2"/>
                                                <div
                                                    className="rounded shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                                                    <div className="bg-secondary sw-1 sh-1 rounded position-relative"/>
                                                </div>
                                                <div
                                                    className="w-100 d-flex h-100 justify-content-center position-relative">
                                                    <div className="line-w-1 bg-separator h-100 position-absolute"/>
                                                </div>
                                            </Col>
                                            <Col className="mb-4">
                                                <div className="h-100">
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex flex-column">
                                                            <Button variant=""
                                                                    className="p-0 pt-1 heading text-left text-secondary">
                                                                {item?.status}
                                                            </Button>
                                                            <div className="text-muted">{new Date(item?.createdAt + "Z")
                                                                ?.toLocaleString()}</div>
                                                            <div className="text-muted mt-1">
                                                                {/*{props.description}*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <hr/>
        </ResponsiveContainerComponent>
    )
}