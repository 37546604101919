import React, { useContext, useEffect } from 'react';
import { LinkButtonComponent } from '../../components/buttons/linkButtonComponent';
import {HOST_CONFIGS, AWS_CONFIGS, APP_DOMAIN, ROUTE_VALUES} from '../../configs.js';
import { UtilitiesContext } from '../../providers/utilitiesProvider';
import {AuthenticationTemplate} from "./authenticationTemplate";
import {Link, useLocation} from "react-router-dom";

export const AUTHENTICATION_URI =
    `${ HOST_CONFIGS.AUTH_HOST }/login?client_id=${ AWS_CONFIGS.COGNITO_CLIENTID }&response_type=token&scope=${ AWS_CONFIGS.COGNITO_SCOPES }&redirect_uri=${ APP_DOMAIN }${ AWS_CONFIGS.COGNITO_LOGINRESULT_URL }`

export const AuthenticationSigninPromptView = (props) =>
{
    const location = useLocation();
    const url = location.pathname;
    const { setDynamicViewTitleHTML } = useContext( UtilitiesContext );
    useEffect( () =>
    {
        setDynamicViewTitleHTML( "Welcome to Applystart" );
    });

    const authenticationUriWithRedirectUrl = AUTHENTICATION_URI+`&state=${ url }`;

    return (
       <AuthenticationTemplate title={"Welcome"}>
           <LinkButtonComponent className={"authentication-button"} title={"Sign In"} bsIconClassName={"unlock-fill"} href={authenticationUriWithRedirectUrl} variant={"secondary"} />
           <hr/>
           <h6>Don't have an account yet?</h6>
           <br/>
           <LinkButtonComponent variant={"primary"} className={"authentication-button"} title={"Signup as an applicant"} bsIconClassName={"mortarboard-fill"} href={ROUTE_VALUES.SIGNUP} />
            <br/>
           <div className={"text-left"}>
               View <Link to={ROUTE_VALUES.APP_HOMEPAGE}><strong>Colleges and Universities</strong></Link> available
           </div>
       </AuthenticationTemplate>
    )
}