import React, {useCallback, useContext, useEffect, useState} from 'react';
import {UtilitiesContext} from '../../providers/utilitiesProvider';
import {Link, useHistory, useParams} from "react-router-dom";
import {AuthorizationContext} from '../../providers/authorizationProvider';
import {AWSCONSTANTS, DATA_VALUES} from '../../utils/constants';
import {PreloaderComponent} from '../../components/preloaderComponent';
import {ApplicantPersonalInformationTab} from "./applicantPersonalInformationTab";
import {ApplicantAcademicsTab} from "./applicantAcademicsTab";
import {ApplicantDocumentTab} from "./applicantDocumentTab";
import {ApplicantFamilyTab} from "./applicantFamilyTab";
import {DFCTab} from "../../components/dfc/dfcTab";
import {NotFoundView} from "../notFoundView";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {ApplicantContactInformationTab} from "./applicantContactInformationTab";
import {ROUTE_VALUES} from "../../configs";
import {ApplicantLanguagesTab} from "./applicantLanguagesTab";

export const ApplicantUpdateProfileView = (props) => {
    const params = useParams();
    const {GetUserAttribute} = useContext(AuthorizationContext);
    const {GetDataFromServer, setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    const [applicantData, setApplicantData] = useState(DATA_VALUES.LOADING);
    const [isEditable, setIsEditable] = useState(false);
    const [applicantId, setApplicantId] = useState(DATA_VALUES.NULL);

    const LoadApplicant = useCallback(() => {
        if (applicantId)
            GetDataFromServer(`/applicant/index/${applicantId}`, setApplicantData);
    }, [applicantId]);

    useEffect(() => {
        LoadApplicant();
    }, [applicantId]);

    useEffect(() => {
        setApplicantId(GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILE));
    }, [GetUserAttribute]);


    useEffect(() => {
        if (applicantData && applicantData !== DATA_VALUES.NULL && applicantData !== DATA_VALUES.LOADING)
            setDynamicViewTitleHTML(`${applicantData.name} | Applicant ID: ${applicantData.displayIndex}`);
        if (applicantId === GetUserAttribute("profile"))
            setIsEditable(true);
        else setIsEditable(false);
    }, [setDynamicViewTitleHTML, applicantData]);


    if (!applicantData || applicantData === DATA_VALUES.FAILED)
        return <NotFoundView/>

    return (
        <ResponsiveContainerComponent>
            {
                applicantData === DATA_VALUES.LOADING ?
                    <PreloaderComponent/>
                    :
                  <>
                      <DFCTab endButtonHtml={()=> <Link className={"btn btn-danger mt-2"} to={"/profile"}>Back to Profile</Link>
                      }>
                          <ApplicantPersonalInformationTab title="Personal Information" applicantData={applicantData}  LoadApplicant={()=>{
                              LoadApplicant();
                              window.location.href = ROUTE_VALUES.APPLICANT_UPDATEPROFILE+"?tabId=Languages";
                          }}
                                                             setApplicantData={setApplicantData}
                                                           isEditable={isEditable} isEditing={true}/>

                          <ApplicantLanguagesTab title="Languages" applicantData={applicantData} LoadApplicant={LoadApplicant}
                                                           setApplicantData={setApplicantData}
                                                           isEditable={isEditable}/>

                          <ApplicantAcademicsTab title="Academics" applicantData={applicantData}
                                                 setApplicantData={setApplicantData}
                                                 isEditable={isEditable}/>
                          <ApplicantFamilyTab title="Family" applicantData={applicantData}
                                              setApplicantData={setApplicantData}
                                              isEditable={isEditable}/>
                          <ApplicantDocumentTab title="Documents" applicantData={applicantData}
                                                setApplicantData={setApplicantData}
                                                isEditable={isEditable} isEditing={true}/>
                          <ApplicantContactInformationTab title="Contact Information" applicantData={applicantData} LoadApplicant={()=>{
                              LoadApplicant();
                              window.location.href = "/profile";
                          }}
                                                          setApplicantData={setApplicantData}
                                                          isEditable={isEditable} isEditing={true}/>
                      </DFCTab>

                  </>
            }
        </ResponsiveContainerComponent>
    )
}