import React, {useContext, useEffect} from "react";
import {LinkButtonComponent} from "../../components/buttons/linkButtonComponent";
import {AuthorizationContext} from "../../providers/authorizationProvider";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {AuthenticationTemplate} from "./authenticationTemplate";
import {DATA_VALUES, VIEWTITLE_VALUES} from "../../utils/constants";
import {PreloaderComponent} from "../../components/preloaderComponent";

export const AuthenticationSignoutView = () => {
    const {setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    const {InternalLogout, currentUser, access_token} = useContext(AuthorizationContext);
    useEffect(() => {
        setDynamicViewTitleHTML(VIEWTITLE_VALUES.EMPTY);
        InternalLogout();
    }, [InternalLogout])
    return (
        <AuthenticationTemplate title={currentUser !== DATA_VALUES.NULL_USER ? "Logging out" : "You are logged out"}>
            {currentUser !== DATA_VALUES.NULL_USER || access_token ?
                <PreloaderComponent/> :
                <LinkButtonComponent variant={"secondary"} href={"/profile"} internal={true} title={"Log Back In"}
                                     className={"authentication-button"}/>
            }
        </AuthenticationTemplate>
    );
}