import {Link} from "react-router-dom";
import {ROUTE_VALUES} from "../../configs";

export const UpdateProfileInstructions = ()=>{
    return (
        <span className={"text-wrap"}>
            You must <Link to={ROUTE_VALUES.APPLICANT_UPDATEPROFILE} className={"text-decoration-underline text-secondary"}>
            update and complete your profile</Link> before applying to institutions. You only fill up your personal
            information and achievements once and can start applying to all your favorite institutions with ease.
            If you need any assistance or guidance with Applystart, you can <Link to={{ pathname: ROUTE_VALUES.COUNSELING_LINK }}
            target="_blank" className={"text-decoration-underline text-secondary"}> book a counseling session</Link> free of charge!
        </span>
    )
}