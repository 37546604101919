import React from 'react';
import _ from 'lodash'
import { Col } from "react-bootstrap";
import { DFCCheckbox } from "../../components/dfc/field/dfcCheckbox";
import { DFCDateTime } from "../../components/dfc/field/dfcDateTime";
import { DFCText } from "../../components/dfc/field/dfcText";

export const AdminManageTermsForm = (props) => {
    if (!props.data)
        return <div>No data</div>

    return (
        <>
            <Col xs={12}>
                <DFCText title={"Term Name"} accessor={`name`} data={props.data}
                    setData={props.setData}
                    isEditing={props.isEditing}
                />
            </Col>
            <Col xs={12}>
                <DFCDateTime isEditing={props.isEditing}
                    accessor={`fromDate`} title={"Start Date"}
                    required
                    data={props.data} setData={props.setData}
                />
            </Col>

            <Col xs={12}>
                <DFCDateTime isEditing={props.isEditing}
                    accessor={`toDate`} title={"End Date"}
                    required
                    data={props.data} setData={props.setData}
                />
            </Col>

            <Col xs={12}>
                <DFCCheckbox title={"Deprecated"} accessor={`deprecated`} data={props.data}
                    setData={props.setData}
                    isEditing={props.isEditing}
                />
            </Col>
        </>
    );
}