import {useEffect} from "react";
import {ROUTE_VALUES} from "../../configs";

export const SignupRedirection = ()=>{
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const referralCode = urlParams.get('ref');
        if (referralCode != null) {
            window.location.href = `${ROUTE_VALUES.SIGNUP}?ref=${referralCode}`
        }
    }, []);

    return (<>Redirecting...</>)
}