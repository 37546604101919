import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {DFCList} from "../../components/dfc/dfcList";
import {ApplicantAttendedInstitutionForm} from "../../view_forms/applicant/applicantAttendedInstitutionForm";
import {ApplicantActivityForm} from "../../view_forms/applicant/applicantActivityForm";
import {ApplicantActivityHeaderContent} from "../../view_forms/applicant/applicantActivityHeaderContent";
import {ROUTE_VALUES} from "../../configs";
import {UtilitiesContext} from "../../providers/utilitiesProvider";

export const ApplicantAcademicsTab = (props) => {
    return (
        <Row>
            <Col xs={12}>
                <DFCList required={true} title={"Academic Background"}
                         isEditable={props.isEditable} isEditing={props.isEditing} modalSize={"lg"}
                         addInstructions={"You may upload attachments to this item after saving the document details"}
                         headerContent={(dProps) => <h6>{_.get(dProps.data, "name")}</h6>}
                         formContent={ApplicantAttendedInstitutionForm}
                         getListDataUrl={`/applicant/list/${props.applicantData.id}/AttendedInstitutions`}
                         deleteDataUrl={`/applicant/list/${props.applicantData.id}/AttendedInstitutions`}
                         editDataUrl={`/applicant/list/${props.applicantData.id}/AttendedInstitutions`}
                         addDataUrl={`/applicant/list/${props.applicantData.id}/AttendedInstitutions`}
                />
            </Col>

            <Col xs={12}>
                <DFCList title={"ECA and Achievements"} modalSize={"lg"}
                         isEditable={props.isEditable} isEditing={props.isEditing}
                         addInstructions={"You may upload attachments to this item after saving the document details"}
                         headerContent={ApplicantActivityHeaderContent} formContent={ApplicantActivityForm}
                         getListDataUrl={`/applicant/list/${props.applicantData.id}/Activities`}
                         deleteDataUrl={`/applicant/list/${props.applicantData.id}/Activities`}
                         editDataUrl={`/applicant/list/${props.applicantData.id}/Activities`}
                         addDataUrl={`/applicant/list/${props.applicantData.id}/Activities`}
                />
            </Col>

        </Row>
    )
}