import React from 'react';
import {DFCForm} from '../../components/dfc/dfcForm';
import {ApplicantPersonalInformationForm} from '../../view_forms/applicant/applicantPersonalInformationForm';
import {Button, Col, Row} from "react-bootstrap";
import {DFCList} from "../../components/dfc/dfcList";
import {ApplicantLanguageForm} from "../../view_forms/applicant/applicantLanguageForm";
import {ROUTE_VALUES} from "../../configs";

export const ApplicantPersonalInformationTab = (props) => {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <DFCForm title="Personal Information"
                             isEditable={props.isEditable} editWithModal={false}
                             formContent={ApplicantPersonalInformationForm}
                             data={props.applicantData} LoadData={props.LoadApplicant} setData={props.setApplicantData}
                             saveDataUrl={`/applicant/index?Validator=ApplicantPersonalInformationValidator`}
                             saveHttpMethod={"put"} isEditing={props.isEditing}
                    />
                </Col>
            </Row>
        </>
    )
}