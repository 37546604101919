import React, {useEffect, useState, Children} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {DATA_VALUES} from "../../utils/constants";
import {Button, Col, Row} from "react-bootstrap";
import {ROUTE_VALUES} from "../../configs";

export const DFCTab = (props) => {
    const history = useHistory();
    const location = useLocation();
    const queries = new URLSearchParams(location.search);

    const tabChildrenArray = Children.toArray(props.children);
    const [activeIndex, setActiveIndex] = useState(-1);

    const ChangeActiveIndex = (positive)=>{
        let _activeIndex = activeIndex;
        if(positive)
            _activeIndex++;
        else _activeIndex--;

        if(activeIndex >= tabChildrenArray.length)
            _activeIndex = 0;
        else if (activeIndex <= -1)
            _activeIndex = tabChildrenArray.length-1;

        SetActiveIndex(_activeIndex);
    }

    const SetActiveIndex = (activeIndex) =>{
        const tabTitle = tabChildrenArray[activeIndex].props.title.replaceAll(' ','');
        history.push(`?tabId=${tabTitle}`);
        setActiveIndex(activeIndex);
    }

    useEffect(() => {
        const _tabIdInQuery = queries.get("tabId");
        let _tabIndex = 0;
        if (_tabIdInQuery) {
            tabChildrenArray.forEach((x, index) => {
                    if (_tabIdInQuery === x.props.title?.replaceAll(" ", ""))
                        _tabIndex = index;
                }
            )
        }
        setActiveIndex(_tabIndex);
    }, [tabChildrenArray]);


    return (
        <div id={props.id} >

            <ul className={"d-none d-md-flex nav nav-tabs dark bg-primary sticky-top top-navsize"} >
                {
                    tabChildrenArray.map((item, index) =>
                        <li key={index} className={"nav-item text-small text-md-large "+(activeIndex === index?"selected":"")} onClick={() => SetActiveIndex(index)}>
                            <span className="nav-link p-3 text-light border border-left-0 border-top-0 border-bottom-0 cursor-pointer "
                               aria-current="page" href="#">{item.props.title}</span>
                        </li>
                    )
                }

            </ul>

            <div className={"d-flex  d-md-none sticky-top top-navsize"} style={{left:"0", bottom:"0"}}>
                <select className={"form-control rounded-0  w-100"} onChange={(e) => SetActiveIndex(e.target.value)}>
                    {
                        tabChildrenArray.map((item, index) =>
                            <option key={index} value={index}>{item.props.title}</option>
                        )
                    }
                </select>
            </div>

            <div>
                {
                    tabChildrenArray.map((child, index) =>
                        index === activeIndex ? React.cloneElement(child, {...props, ChangeActiveTabIndex:ChangeActiveIndex}) : <></>
                    )
                }
            </div>

            {
                !props.hideIndex &&
                <Row className="text-center m-auto py-3">
                    <Col>
                        {activeIndex !== 0 && <Button size={"sm"} className={"m-1"} variant={"info"} onClick={()=>{ChangeActiveIndex(false)}}>Prev</Button>}
                        <span
                            className="text-primary p-2 font-weight-bold text-small">Tab {activeIndex + 1} of {tabChildrenArray.length ?? 1}
                    </span>
                        {activeIndex !== tabChildrenArray.length-1 && <Button size={"sm"} className={"m-1"} variant={"info"}  onClick={()=>{ChangeActiveIndex(true)}}>Next</Button>}
                    </Col>
                    {props.endButtonHtml &&
                        <Col xs={12}>
                            <props.endButtonHtml/>
                        </Col>}
                </Row>
            }
        </div>
    )
}