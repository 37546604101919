import React from "react";
import { DFCText } from "../../components/dfc/field/dfcText";
import {Col} from "react-bootstrap";
import {FIELDTYPES} from "../../utils/constants";

export const ReferralForm = ( props ) =>
{
    return (
        <Col>
            <DFCText title={"Enter Email"} accessor={`email`} required
                     data={props.data} setData={props.setData} type={FIELDTYPES.EMAIL}
                     isEditing={props.isEditing} />
        </Col>
    )
}