import React, {useContext, useEffect} from 'react';
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {Badge, ListGroup} from "react-bootstrap";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {Link} from "react-router-dom";

export const AdminProfileSubView = () => {
    const { setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    useEffect(() => {
        setDynamicViewTitleHTML("Admin Profile");
    }, []);

  return(
      <ResponsiveContainerComponent>
          <ListGroup className={"text-large pt-5"}>
              <ListGroup.Item><Link to={"/admin/applicants"}>Applicants/Influencers</Link></ListGroup.Item>
              <ListGroup.Item><Link to={"/admin/create-institution"}>Create Institution</Link></ListGroup.Item>
              <ListGroup.Item><Link to={"/admin/countries"}>Countries</Link></ListGroup.Item>
              <ListGroup.Item><Link to={"/admin/manage-terms"}>Manage Terms</Link></ListGroup.Item>
          </ListGroup>
      </ResponsiveContainerComponent>
  )
}