import React, {useContext} from 'react';
import {Badge, Button, Col, Row} from "react-bootstrap";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {RedirectToLink} from "../../utils/helpers";
import Tippy from "@tippyjs/react";

export const NotificationsHeaderForm = (props) => {
    const {SaveDataToServerWithoutConfirmation} = useContext(UtilitiesContext);

    const HandleClick = (notification) => {
        //mark seen and redirect
        SaveDataToServerWithoutConfirmation(`/Notification/Index`, 'put', notification, null, true, () => RedirectToLink(notification.link));
    }

    const MarkRead = (notification)=>{
        SaveDataToServerWithoutConfirmation(`/Notification/Index`, 'put', notification, null, true, ()=>window.location.reload());
    }

    if (!props.data)
        return <div>No data</div>

    return (
        <>
            <Col xs={8} className={"font-weight-bold"} onClick={()=>HandleClick(props.data)}>
                <span className={"text-medium hover-text-secondary mr-2"}>{props.data.name}</span>
                {
                    !props.data.deprecated &&
                    <>
                        <Badge variant={"warning"}>New</Badge>
                    </>

                }
                {new Date(props.data.createdAt) ?
                    <div className={"text-muted text-small"}>
                        {new Date(props.data.createdAt + "Z")?.toLocaleString("default", {
                            dateStyle: "long",
                            timeStyle: "short"
                        })}
                    </div>
                    : <></>}

            </Col>
            <Col xs={4} className={"p-0 text-right"}>

                <Tippy content={"Mark as Read"}>
                    <Button onClick={()=>MarkRead(props.data)} className={"p-1 m-3"} variant={"outline-success"} size={"sm"}><i className={"bi bi-envelope-open"}></i></Button>
                </Tippy>
            </Col>
        </>
    );
}