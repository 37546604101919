
import React, { useContext, useEffect, useState } from 'react'
import { UtilitiesContext } from '../../providers/utilitiesProvider';
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {useHistory, useLocation} from "react-router-dom";
import {Row} from "react-bootstrap";
import {DFCList} from "../../components/dfc/dfcList";
import {NotificationsHeaderForm} from "../../view_forms/account/notificationsHeaderForm";

export const NotificationsView = ( props ) =>
{
    const { setDynamicViewTitleHTML } = useContext( UtilitiesContext );
    useEffect( () =>
    {
        setDynamicViewTitleHTML( "Notifications" )
    },[]);

    return (
        <ResponsiveContainerComponent>
            <Row>
                <DFCList title={""} hideBody isEditable={true}
                        getListDataUrl={"/Notification/Index"}
                         headerContent={NotificationsHeaderForm}
                         sortFunction={(x,y)=>x.deprecated-y.deprecated}
                />

            </Row>
        </ResponsiveContainerComponent>
    )
}