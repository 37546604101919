import React, {useContext, useEffect, useState} from "react";
import { DFCText } from "../../components/dfc/field/dfcText";
import {Col} from "react-bootstrap";
import {DATA_VALUES, FIELDTYPES} from "../../utils/constants";
import {UtilitiesContext, UtilitiesProvider} from "../../providers/utilitiesProvider";
import {DFCSelect} from "../../components/dfc/field/dfcSelect";
import {PreloaderComponent} from "../../components/preloaderComponent";

export const AdminInfluencerReferralCreationForm = ( props ) =>
{
    const {GetDataFromServer} = useContext(UtilitiesContext);
    const [applicants, setApplicants] = useState([]);
    const [candidates, setCandidates] = useState([]);

    useEffect(() => {
        GetDataFromServer("/applicant/influencer", setApplicants, null, null);
    }, []);

    useEffect(()=>{
        if(applicants && Array.isArray(applicants))
        {
            const _candidates = applicants.map((item)=> {return {
                label: `${item.name} - ${item.preferredEmail} - ${item.displayIndex}`,
                value: item.id
            }});
            setCandidates(_candidates);
        }
    },[applicants])

    if(candidates === DATA_VALUES.LOADING)
        return <PreloaderComponent/>

    return (
       <>

           <Col xs={12}>
               <DFCText required title={"Enter custom code"} accessor={`influencerInformation.referralCode`} instructions={"Leaving this blank will generate a random code"}
                        data={props.data} setData={props.setData} isEditing={props.isEditing} />
           </Col>
           <Col xs={12}>
               <DFCSelect required title={"Enter email of applicant that you want to mark as influencer"} accessor={'id'}
                        data={props.data} setData={props.setData} isEditing={props.isEditing} options={candidates}/>
           </Col>
       </>
    )
}