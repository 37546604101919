import React from 'react';
import {DATA_VALUES, VALID_VALUES} from "../../utils/constants";
import {PreloaderComponent} from "../../components/preloaderComponent";
import {ApplicantProfileSubView} from "../applicant/applicantProfileSubView";
import {GetFormattedString} from "../../utils/helpers";
import {Link} from "react-router-dom";
import {ROUTE_VALUES} from "../../configs";
export const ApplicationApplicantTab = (props) => {

    if(!props.applicationData)
        return (<div>No Application</div>)

    if(props.applicationData === DATA_VALUES.LOADING)
        return <PreloaderComponent/>

    const status = GetFormattedString(props.applicationData?.statusHistory?.sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))[0].status)?.toLowerCase();

    return (
        <>
            {
                (status === VALID_VALUES.APPLICATIONSTATUSES.InProgress ||
                    status === VALID_VALUES.APPLICATIONSTATUSES.ReviewAndSubmit) &&
                    <div className={"w-100 bg-warning p-2 text-extra-small font-weight-bold mt-2"}>

                        <i className={"bi bi-exclamation-square  mr-2"}></i>
            You may <Link  to={ROUTE_VALUES.APPLICANT_UPDATEPROFILE} className={"text-decoration-underline text-secondary "}>
                              update your profile</Link> <strong className={"font-weight-bolder"}>before submitting this application</strong>. Changes made to your applicant profile after submission will not be reflected
                              in the application and will not be available to this institution.

                    </div>
            }
            <ApplicantProfileSubView applicantId={props.applicationData?.applicantSnapshot?.id} isSnapshot={true}/>
        </>

    )
}