import React, {useState} from 'react';
import {Col} from "react-bootstrap";
import {DFCText} from "../../components/dfc/field/dfcText";
import {FIELDTYPES} from "../../utils/constants";
import {DFCDocuments} from "../../components/dfc/dfcDocuments";
import {ENV} from "../../configs";

export const ApplicationAdditionalInformationForm = (props) => {
    const [additionalInformationDocumentCategory] = useState([{label:"Additional Information", value:"Additional_Information"}]);
    return (
        <>
            <Col xs={12}>
                <DFCText title={"Title/Subject"} accessor={`name`} required
                         data={props.data} setData={props.setData}
                         isEditing={props.isAdding && props.isEditing}/>
            </Col>

            <Col xs={12}>
                <DFCText title={"Details"} accessor={`details`} required
                         data={props.data} setData={props.setData} type={FIELDTYPES.TEXTAREA}
                         isEditing={props.isAdding && props.isEditing}/>
            </Col>

            {
                !props.isAdding &&
                <Col xs={12}>
                    <DFCDocuments instructionTitle={"Additional Information"}
                                  getListDataUrl={`/document/list/AdditionalInformation/${props.data?.id}`}
                                  addDataUrl={`/document/list/AdditionalInformation/${props.data?.id}`}
                                  deleteDataUrl={'/document/index'}
                                  isEditable={props.isEditable} isEditing={true}
                                  bucketName={`applystart-fs-application-${ENV}`}
                                  keyPrefix={props.formData.applicationId} documentCategories={additionalInformationDocumentCategory}
                    />
                </Col>
            }
        </>
    )
}