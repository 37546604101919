import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { AuthorizationContext } from './authorizationProvider';
import { AppendServerBaseUrl, CleanseDataForServer, GenerateMessageContentForToast, ResolveJSON } from '../utils/helpers';
import {DATA_VALUES, TOAST_SETTINGS, VALID_VALUES, VIEWTITLE_VALUES} from '../utils/constants';
import {Button, Modal} from "react-bootstrap";

export const UtilitiesContext = createContext();
export const UtilitiesProvider = ( props ) =>
{
    const [ dynamicViewTitleHTML, setDynamicViewTitleHTML ] = useState( VIEWTITLE_VALUES.UNSET );
    //confirm
    const [ modalConfirmFunction, setModalConfirmFunction ] = useState();
    const [showModal, setShowModal] = useState(false);


    const { access_token, logoutUri } = useContext( AuthorizationContext );
    const [countries, setCountries] = useState();

    async function AxiosRequest ( url, method, data, stateSetter, silent = true, onComplete, onFail, onError, contentType = "application/json" )
    {
        var _ = await toast.remove();
        let _url = AppendServerBaseUrl( url );
        
        if ( !silent )
        {
            toast.loading( `loading`, { id: TOAST_SETTINGS.IDS.REQUEST } );
        }

        if ( stateSetter )
            stateSetter( DATA_VALUES.LOADING );

        axios( {
            method: method,
            url: _url,
            data: data,
            timeout: 40000,
            headers: { "Authorization": access_token ?? "", "Content-Type": contentType }
        } )
            .then( ( res =>
            {
                if ( res.data )
                {
                    const _resolved = ResolveJSON( res.data?.data );
                    if ( stateSetter )
                        stateSetter(  _resolved ?? DATA_VALUES.FAILED );

                    if ( res.data?.warnings?.length > 0 )
                    {
                        const warningContent = GenerateMessageContentForToast( res.data?.warnings );
                        toast( warningContent, {id: TOAST_SETTINGS.IDS.WARNINGS} );
                    }
                    
                    if ( res.data?.errors?.length > 0 )
                    {
                        const errorContent = GenerateMessageContentForToast( res.data?.errors, VALID_VALUES.MESSAGETYPES.ERROR );
                        if (onError)
                            onError();
                        toast( errorContent, {id: TOAST_SETTINGS.IDS.REQUEST, duration: 10000} );
                        console.log(res.data)
                        
                    } else
                    {
                        if ( onComplete )
                            onComplete();
                        if ( !silent )
                            toast.success( "success", { id: TOAST_SETTINGS.IDS.REQUEST } );
                    }

                }

            } ) )
            .catch( ( err ) =>
            {
                console.log(err)
                const response = err?.response;

                if ( stateSetter )
                    stateSetter( DATA_VALUES.FAILED );

                if ( response?.data?.warnings?.length > 0 )
                {
                    const warningContent = GenerateMessageContentForToast( response.data.warnings );
                    toast(warningContent, {id: TOAST_SETTINGS.IDS.WARNINGS} );
                }

                if ( !response )
                {
                    toast.error( `API Error - ${ err.message } - Please try again. 
                    Report to team@applystart.com if problem persists.`, {id: TOAST_SETTINGS.IDS.REQUEST} );
                    return;
                }
                if ( response.status === 401 )
                {  
                    window.location.href = logoutUri;
                }
                if ( stateSetter )
                    stateSetter( null );
               
                if ( onFail )
                    onFail();

                const errorContent = response.data?.errors?.length > 0 ? GenerateMessageContentForToast( response.data.errors,  VALID_VALUES.MESSAGETYPES.ERROR )
                    : "API Error: Cannot proceed with current operation.";
                toast( errorContent, {id: TOAST_SETTINGS.IDS.REQUEST, duration:10000} );


            } );
    }

    function GetDataFromServer ( url, stateSetter, silent = true, data = null, method = "get" )
    {
        AxiosRequest( url, method, data, stateSetter, silent );
    }


    function SaveDataToServerWithoutConfirmation ( url, httpmethod, data, stateSetter, silent = true, onComplete, onFail, onError, contentType = "application/json", )
    {
        data = CleanseDataForServer( data );
        AxiosRequest( url, httpmethod, data, stateSetter, silent, onComplete, onFail, onError, contentType );
    }

    function SaveDataToServer ( url, httpmethod, data, stateSetter, silent = true, onComplete, onFail, onError, contentType = "application/json", )
    {
        const serverCall = () =>
        {
            return () =>
            {
                data = CleanseDataForServer( data );
                AxiosRequest( url, httpmethod, data, stateSetter, silent, onComplete, onFail, onError, contentType );
            }
        };
        setModalConfirmFunction( serverCall );
        setShowModal(true);
    }

    useEffect(() => {
        GetDataFromServer('/system/countries', setCountries);
    }, []);

    const returnee = {
        dynamicViewTitleHTML,
        setDynamicViewTitleHTML,
        GetDataFromServer,
        SaveDataToServerWithoutConfirmation,
        SaveDataToServer,
        countries
    };

    return (
        <UtilitiesContext.Provider value={returnee}>

            <Modal show={showModal}>
                <Modal.Header>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="tertiary" onClick={()=>setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={() => { modalConfirmFunction(); setShowModal(false); }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            {props.children}
        </UtilitiesContext.Provider>
    );

}