import React, {useContext} from 'react';
import {AuthorizationContext} from '../../providers/authorizationProvider';
import {Button, Col, Dropdown, Image, Row} from "react-bootstrap";
import Tippy from "@tippyjs/react";
import {DATA_VALUES} from "../../utils/constants";
import {PreloaderComponent} from "../preloaderComponent";
import {LinkButtonComponent} from "../buttons/linkButtonComponent";
import {AUTHENTICATION_URI} from "../../views/authentication/authenticationSigninPromptView";
import {ROUTE_VALUES} from "../../configs";
import {Link} from "react-router-dom";

export const UserSubComponent = (props) => {

    const {logoutUri, GetUserAttribute, currentUser} = useContext(AuthorizationContext);

    const UserDropdownElement = React.forwardRef(({children, onClick}, ref) => (

        <>
            <Button variant={"light"} className={"btn btn-circle border-primary ml-2 p-0 overflow-hidden place-content-center nav-user-btn nav-route"}>
                <Image src={GetUserAttribute("picture")} href=""
                       ref={ref}
                       onClick={(e) => {
                           e.preventDefault();
                           onClick(e);
                       }} className={"shadow-very-deep object-fit-cover img-fluid "}/>
            </Button>
            <Tippy content={GetUserAttribute("name")+" | "+GetUserAttribute("email")}>
                <div className={"text-truncate text-extra-small mb-1 d-none d-md-inline"}>
                    {GetUserAttribute("name")} | {GetUserAttribute("email")}
                </div>
            </Tippy>
        </>
    ));
    if (currentUser === DATA_VALUES.LOADING)
        return (
            <PreloaderComponent small/>
        );

    return (

        currentUser === DATA_VALUES.NULL_USER ?
            <Row className={"float-right"}>
                <LinkButtonComponent bsIconClassName={"unlock-fill"} variant={"secondary"} href={AUTHENTICATION_URI+"&state="+window.location.pathname} title={"Log In"}/>
                <LinkButtonComponent variant={"primary"} className={"authentication-button"} title={"Signup"} bsIconClassName={"mortarboard-fill"} href={ROUTE_VALUES.SIGNUP} />
            </Row>
            :
            <>
                <Dropdown className={"d-inline nav-user"}>

                    <Dropdown.Toggle as={UserDropdownElement} />

                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="1" href={logoutUri} className={"font-weight-bold"}>
                            <i className="bi bi-door-open mr-2"></i>
                            Log Out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

            </>

    )
}