import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {DFCDocuments} from "../../components/dfc/dfcDocuments";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {DATA_VALUES} from "../../utils/constants";


export const ApplicantDocumentTab = (props) => {

    const {GetDataFromServer} = useContext(UtilitiesContext);
    const [documentCategories, setDocumentCategories] = useState(DATA_VALUES.NULL);
    const [applicantDocumentCategoriesEnumOptions, setApplicantDocumentCategoriesEnumOptions] = useState([]);


    const categoriesToExclude = ["Additional Information", "Decision Document", "Activity Document","Institution Cover Image", "Other"]
    useEffect(() => {
        GetDataFromServer( '/system/enumchoices/DocumentCategory', setDocumentCategories);
    }, []);

    useEffect(() => {
        if(documentCategories && documentCategories !== DATA_VALUES.LOADING)
            setApplicantDocumentCategoriesEnumOptions(documentCategories.filter(x=>!categoriesToExclude.includes(x.label)))
    }, [documentCategories]);

    return (
        <>
            <Row className={"mt-2"}>
                <Col xs={12}>
                    <DFCDocuments independent instructionTitle={"Academic Documents"}
                                  getListDataUrl={`/document/list/Applicants/${props.applicantData.id}`}
                                  addDataUrl={`/document/list/Applicants/${props.applicantData.id}`}
                                  deleteDataUrl={'/document/index'} documentCategories={applicantDocumentCategoriesEnumOptions}
                                  isEditable={props.isEditable} isEditing={props.isEditing}
                    />
                </Col>
            </Row>
        </>
    )
}