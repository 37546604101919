/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, {useContext, useEffect, useState} from 'react'
import {Col, Row, Form, Card, Button} from "react-bootstrap";
import {DFCSelect} from "./field/dfcSelect";
import {DATA_VALUES, FILTER_TYPES} from '../../utils/constants';
import {UtilitiesContext} from '../../providers/utilitiesProvider';
import {PreloaderComponent} from "../preloaderComponent";
import {useHistory, useParams} from "react-router-dom";
import {EscapeRegex} from "../../utils/helpers";
import {DFCText} from "./field/dfcText";

export const joinerOptions = {
    and: "%26%26", or: "%7C%7C"
}

export const DFCFilter = (props) => {
    const history = useHistory();
    const urlQueries = new URLSearchParams(window.location.search);
    const {GetDataFromServer} = useContext(UtilitiesContext);
    const [data, setData] = useState(DATA_VALUES.NULL);

    const [currentQueries, setCurrentQueries] = useState(JSON.parse(decodeURIComponent(urlQueries.get('queries'))) ?? props.defaultQueries ?? null);
    // format of currentQueries
    // {expressionFormatFromCategory: {finalExpressionWithValue,joiner}}

    const [pageIndex, setPageIndex] = useState(0);
    const takePerPage = props.takePerPage ?? 50;

    const [currentDataValuesForSelects, setCurrentDataValuesForSelects] = useState( {});



    const GetValueFromExpression = (expressionTemplate, expressionValue) => {
        // Extract the variable name placeholder from the template string
        const variablePlaceholder = /\{\$\w+\}/.exec(expressionTemplate)[0];

        // Create a regex pattern to find the value in the comparison string
        const escapedTemplateString = EscapeRegex(expressionTemplate);
        const patternString = escapedTemplateString.replace(EscapeRegex(variablePlaceholder), '"([^"]+)"');
        const pattern = new RegExp(patternString);

        // Find the value that matches the pattern in the comparison string
        const match = expressionValue.match(pattern);
        return match ? match[1] : "No match found"
    }

    const LoadData = () => {
        let _queries = "";
        let _url = props.getDataUrl;

        if(currentQueries){

            Object.keys(currentQueries).forEach((expressionKey, index) => {
                const expressionObject = currentQueries[expressionKey]
                if (index === 0) {
                    _queries += "data=>" + expressionObject.expression;
                } else {
                    _queries += (expressionObject.joiner + expressionObject.expression)
                }

                const expVal = GetValueFromExpression(expressionKey, expressionObject.expression);
                currentDataValuesForSelects[expressionKey] = {value: expVal};
            });

            if (!_url.includes("?"))
                _url += `?queries=${_queries}`;
            else _url += `&queries=${_queries}`;

            //add this current query string to the url for reuse
            const urlParams = new URLSearchParams(window.location.search);
            const encodedCurrentQueries = encodeURIComponent(JSON.stringify(currentQueries));
            if(encodedCurrentQueries !== urlParams.get('queries'))
            {
                urlParams.set("queries", encodedCurrentQueries)
                history.push({
                    pathname: window.location.pathname,
                    search: urlParams.toString(),
                });
            }
        }

        if(_url.includes('?'))
            _url += `&pageIndex=${pageIndex}&take=${takePerPage}`
        else
            _url += `?pageIndex=${pageIndex}&take=${takePerPage}`

        setData(DATA_VALUES.NULL);
        GetDataFromServer(_url, setData); //expects a FilteredResultsResponse



        //scroll page to top
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        LoadData();
    }, [pageIndex, currentQueries, props.getDataUrl]);




    const UpdateQueryExpression = (expression, value, joiner = joinerOptions.and) => {
        const _tempQueries = {...currentQueries};
        if (value === "") {
            delete _tempQueries[expression];
            if(Object.keys(_tempQueries).length > 0)
                setCurrentQueries(_tempQueries);
            else {
                console.log(_tempQueries)
                history.push({
                    pathname: window.location.pathname,
                });
                setCurrentQueries(null);

            }
            return;
        }
        _tempQueries[expression] = {expression: expression.replace('{$value}', `"${value}"`), joiner: joiner};
        setCurrentQueries(_tempQueries);
        setPageIndex(0);
    }

    const DebounceSearch = (func, timeout = 500) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    const pageStartCount = () => (data.count) > 0 ? ((data.pageIndex * data.take) + 1) : 0;
    const pageEndCount = () => ((data.pageIndex * data.take) + data.take) > data.count ? data.count : ((data.pageIndex * data.take) + data.take);

    const TransformedData = () => {
        if (!data || data === DATA_VALUES.LOADING)
            return data;

        return data.data;
    }

    return (
        <>
            <Row>
                <Col md={props.filterOptionSize ?? 4}>
                    <Card className={"mt-2"}>
                        <Card.Header
                            className={"p-2 bg-primary text-light  " + props.headerClassName}>{props.title}</Card.Header>
                        <Card.Body className={"p-3"}>
                            {
                                props.categories?.map((item, index) =>
                                    <div className={"mb-2"}>
                                        <h5>{item.name}</h5>
                                        {
                                            item.filterType === FILTER_TYPES.SEARCH ?
                                               <DFCText placeholder={"Search..."} isEditing={true}
                                                         data={currentDataValuesForSelects[item.expression]}
                                                         accessor={"value"}
                                                         setData={DebounceSearch((e) => UpdateQueryExpression(item.expression, e.value?.toLowerCase()))}
                                                        noToast={true}
                                                />
                                                :
                                                item.filterType === FILTER_TYPES.SELECT &&
                                                <Row>
                                                    <Col className={"pr-2"}>
                                                        <DFCSelect options={item.options} isEditing={true}
                                                                   data={currentDataValuesForSelects[item.expression]}
                                                                   nullable={true}
                                                                   accessor={"value"}
                                                                   setData={(selection) => UpdateQueryExpression(item.expression, selection.value)}
                                                        />
                                                    </Col>
                                                    <Col xs={2} className={"m-0 p-0 align-self-center"}>
                                                        <Button size={"sm"} variant={"outline-dark"} onClick={() => {
                                                            let _temp = {...currentDataValuesForSelects};
                                                            _temp[item.expression] = {value: ""};
                                                            setCurrentDataValuesForSelects(_temp);
                                                            UpdateQueryExpression(item.expression, "")
                                                        }}>x</Button>
                                                    </Col>
                                                </Row>
                                        }
                                    </div>
                                )
                            }


                            {
                                data === DATA_VALUES.LOADING ? <PreloaderComponent small={true}/>
                                    :
                                    data &&
                                    <div className={"text-subtitle"}>

                                        <Button variant={"outline-primary"} size={"sm"} className={"m-1 text-small"}
                                                disabled={data.pageIndex <= 0}
                                                onClick={() => {
                                                    setPageIndex(pageIndex - 1);
                                                }}>Prev</Button>

                                        <>Showing {pageStartCount()} - {pageEndCount()} of {data.count}</>

                                        <Button variant={"outline-primary"} size={"sm"} className={"m-1 text-small"}
                                                disabled={(data.pageIndex * data.take) + data.take >= data.count}
                                                onClick={() => {
                                                    setPageIndex(pageIndex + 1);
                                                }}>Next</Button>
                                    </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <div>
                        {
                            TransformedData() === DATA_VALUES.LOADING ?
                                <PreloaderComponent/> :
                                <props.content data={TransformedData()} contentData={props.contentData}/>
                        }
                        {
                            data && data !== DATA_VALUES.LOADING && (data.count > (data.pageIndex * data.take) + data.take) &&
                            <div className={"text-center my-3"}>
                                <Button variant={"outline-primary"} size={"sm"} className={"m-1 text-small"}
                                        onClick={() => {
                                            setPageIndex(pageIndex + 1);
                                        }}>Show More</Button>
                            </div>
                        }

                    </div>
                </Col>
            </Row>
        </>
    )
}