import React from 'react';
import {Row, Spinner} from "react-bootstrap";


export const PreloaderComponent = (props) => {
    return (
        <div className={"w-100 overflow-x-hidden "}>
            <div className="text-center my-2">
                {props.small ?
                    <img className={"max-w-100"} alt="loader" src={require("../graphics/loading/loading_balls.gif")} />
                    : <img className={"max-w-100"} alt="loader" src={require("../graphics/loading/loading_a.gif")}/>}
            </div>
        </div>
    )
}