import {Col, Container, Navbar as BsNavbar, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {UtilitiesContext} from "../providers/utilitiesProvider";
import {APPLYSTART_VERSION, ROUTE_VALUES} from "../configs";

export const FooterComponent = ()=>{
    const { GetDataFromServer } = useContext(UtilitiesContext);
    const [systemInfo, setSystemInfo] = useState({});

    useEffect(() => {
        GetDataFromServer('/system/index', setSystemInfo);
    }, []);
    return (
        <footer className={"w-100 bottom-0 shadow  text-light text-center position-absolute"}>
            <div className={"bg-primary applystart-view-footer text-shadow"}>
                <Container>
                    <Row className={"p-3"}>
                        <Col xs={4} sm={2} md={6} className={"text-left border-right border-gray"}>
                            <div>Connect With Us</div>
                            <div className={"text-large"}>
                                <a href={"https://www.facebook.com/Applystart.Inc/"} target={"_blank"}>
                                    <i className={"bi bi-facebook m-1  text-light"}></i>
                                </a>
                                <a href={"https://www.instagram.com/applystart/"} target={"_blank"}>
                                    <i className={"bi bi-instagram m-1  text-light"}></i>
                                </a>
                            </div>

                        </Col>

                        <Col xs={8} sm={2} md={6} className={"text-right p-2 p-md-0 text-xs-small "}>
                            <div>Helpline: <a className={"text-light text-underline"} href={"tel:+8801862361386"}>+8801862361386</a></div>
                            <div>Email: <a className={"text-light text-underline"} href={"mailto:team@applystart.com"}>team@applystart.com</a></div>
                            <BsNavbar.Brand href={ROUTE_VALUES.APP_HOMEPAGE} className={"mt-1"}>
                                <img src={require("../graphics/logo/applystart-logo-white.svg").default}
                                     className="img-fluid w-80" alt="applystart-logo"/>
                            </BsNavbar.Brand>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={"bg-secondary p-2 shadow-very-deep text-shadow font-weight-bold"}>
                {
                    systemInfo &&
                    <span title={"core version: "+systemInfo.versionString+" | web version: "+ APPLYSTART_VERSION +" | "+systemInfo.dbConnectionStringParameterName}>&copy;</span>
                }
                Applystart Inc. {" " + new Date().getFullYear()}
            </div>
        </footer>
    )
}