import React from 'react';
import _ from 'lodash'
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {HOST_CONFIGS} from "../../configs";

export const AdminApplicantsForm = (props) => {

    if (!props.data)
        return <div>No data</div>

    return (
        <Col xs={12} className={"font-weight-bold"}>
            <Row>
                <Col xs={6}>
                    <Link to={`/profile/applicant/${props.data?.id}`}
                          className={"text-secondary"}>{props.data?.name}</Link>
                </Col>
                <Col xs={12}>
                    <div className={"text-muted"}>
                        Preferred Email Address: {_.get(props.data, "preferredEmail")}
                    </div>
                </Col>

                {
                    props.data.influencerInformation?.referralCode &&
                    <>
                        <hr/>
                        <Col xs={12}>
                            <div className={"text-muted"}>
                                Influencer Referral:
                                <a className={"ml-1 text-decoration-underline"} href={`${HOST_CONFIGS.APP_DOMAIN}/authentication/signup?ref=${_.get(props.data, "influencerInformation.referralCode")}`}>
                                    {_.get(props.data, "influencerInformation.referralCode")}
                                </a>
                            </div>
                        </Col>
                    </>
                }

            </Row>
        </Col>
    );
}