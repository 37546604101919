export const APPLYSTART_VERSION = "v6.1.004";

export const APP_DOMAIN = window.location.origin;

export const ENV = window.location.origin.startsWith( "http://localhost" ) ? "dev"
    : window.location.origin.startsWith( "https://app-dev.applystart.com" ) ? "dev"
    : window.location.origin.startsWith( "https://app-qas.applystart.com" ) ? "qas"
        : window.location.origin.startsWith( "https://app.applystart.com" ) ? "prd" : "_ERR";

const USE_LOCALHOST_SERVER = (window.location.origin.startsWith( "http://localhost" ) && true);

export const ROUTE_VALUES = {
    APP_HOMEPAGE: "/",
    SIGNUP: "/authentication/signup",
    APPLICANT_UPDATEPROFILE: "/profile/update-applicant",
    COUNSELING_LINK: "https://linktr.ee/applystart",
    NOTIFICATIONS: "/account/notifications",
    PROGRAMS_PAGE: "/programs"
}

export const AWS_CONFIGS = {
    S3BUCKET_URL: `https://applystart-fs-app-${ ENV }.s3.amazonaws.com`,
    COGNITO_CLIENTID: ENV === "prd" ? "10qcndo8daufur5lfp0f8i7tfg" : ENV === "qas" ? "3f9uonk514iiq4ed1pi8onc94v" : /*dev*/ "5e6051hr9t1bgropr769tiu6mf",
    COGNITO_LOGINRESULT_URL: "/authentication/signin",
    COGNITO_LOGOUT_URL: "/authentication/signout",
    COGNITO_SCOPES: "email+openid+phone+profile+aws.cognito.signin.user.admin",
    COGNITO_LOGIN_REDIRECTURI: ROUTE_VALUES.APP_HOMEPAGE
}


export const HOST_CONFIGS = {
    APP_DOMAIN: window.location.origin,
    AUTH_HOST: `https://auth-${ ENV }.applystart.com`,
    BACKEND_HOST: USE_LOCALHOST_SERVER ? "https://localhost:9001" : `https://apoxn-core-${ ENV }.applystart.com`,
}

export const LOOKER_REPORTIDS = {
    INFLUENCER_DASHBOARD: ENV === "prd"? "acc5c4b7-fa42-44eb-bd7e-4e0af864021d": "3db9ec99-c221-4cc6-82fc-bbb97af8d42d"
}