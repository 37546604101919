import {Col} from "react-bootstrap";
import React from "react";
import {FIELDTYPES} from "../../utils/constants";
import {DFCText} from "../../components/dfc/field/dfcText";
import {DFCPhone} from "../../components/dfc/presets/dfcPhone";
import {DFCLink} from "../../components/dfc/field/dfcLink";

export const InstitutionContactsForm = (props) => {
    return (
        <>
            <Col xs={12}>
                <DFCText required={true} title={"Email"} accessor={`institutionEmail`} type={FIELDTYPES.EMAIL}
                         data={props.data} setData={props.setData} IsEditing={props.isEditing} isNotField={true}/>
            </Col>
            <Col xs={12}>
                <DFCPhone required={true} title={"Phone"} accessor={`contactPhone`}
                          data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField={true}/>
            </Col>
            <Col xs={12}>
                <DFCText required={true} title={"DLI"} accessor={`schoolCode`}
                         data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField={true}/>
            </Col>
            <Col xs={12}>
                <DFCLink required={true} title={"Link"} text={props.data?.name}
                         href={props.data?.link} target={"_blank"}
                         isNotField/>
            </Col>

        </>
    )
}