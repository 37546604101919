import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {DFCSelect} from "../field/dfcSelect";
import {UtilitiesContext} from "../../../providers/utilitiesProvider";
import {DATA_VALUES, FIELDTYPES} from "../../../utils/constants";
import {DFCText} from "../field/dfcText";
import {DFCDateTime} from "../field/dfcDateTime";
import {DFCPhone} from "./dfcPhone";
import {DFCAddress} from "./dfcAddress";
import {DFCCheckbox} from "../field/dfcCheckbox";

export const DFCPerson = (props) =>{
    const { GetDataFromServer } = useContext( UtilitiesContext );
    const [ nameTitleEnumOptions, setNameTitleEnumOptions ] = useState( DATA_VALUES.EMPTY_ARRAY );
    const [ maritalStatusEnumOptions, setMaritalStatusEnumOptions ] = useState( DATA_VALUES.EMPTY_ARRAY );
    const [educationLevelEnumOptions, setEducationLevelEnumOptions] = useState();


    useEffect( () =>
    {
        if(props.isEditing){
            GetDataFromServer( '/system/enumchoices/NamePrefixTitle', setNameTitleEnumOptions );
            GetDataFromServer( '/system/enumchoices/MaritalStatus', setMaritalStatusEnumOptions );
            GetDataFromServer('/system/enumchoices/EducationLevel', setEducationLevelEnumOptions);
        }

    }, [ GetDataFromServer, props.isEditing ] );

    const _colSizeMd = 4;

    return (
        <Row>

            <Col xs={12} md={_colSizeMd}>
                <DFCText isEditing={props.isEditing}
                         accessor={`${props.accessor}.firstName`} title={"First Name"}
                         required
                         data={props.data} setData={props.setData} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCText isEditing={props.isEditing}
                         accessor={`${props.accessor}.lastName`} title={"Last Name"}
                         required
                         data={props.data} setData={props.setData} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCDateTime isEditing={props.isEditing}
                             accessor={`${props.accessor}.birthday`} title={"Birthday"}
                             data={props.data} setData={props.setData}
                />
            </Col>


            <Col xs={12} md={_colSizeMd}>
                <DFCText title={"Email"} isEditing={props.isEditing}
                         accessor={`${props.accessor}.email`} type={FIELDTYPES.EMAIL} required
                         data={props.data} setData={props.setData}/>
            </Col>

            <Col xs={12} md={8}>
                <DFCPhone title={"Phone Number"}
                          accessor={`${props.accessor}.phoneNumber`} required isEditing={props.isEditing}
                          data={props.data} setData={props.setData} />
            </Col>
            
        </Row>
    )
}