import React, {useEffect, useState, useContext, useRef} from 'react'
import {Badge, Col, Row} from "react-bootstrap";
import {usePlacesWidget} from "react-google-autocomplete";
import _ from 'lodash'
import {DATA_VALUES, TOAST_SETTINGS} from "../../../utils/constants";
import toast from "react-hot-toast";
import {UtilitiesContext} from "../../../providers/utilitiesProvider";
import {DFCField} from "../field/dfcField";
import {DFCCountrySelect} from "../field/dfcCountrySelect";
import {PreloaderComponent} from "../../preloaderComponent";
import {DFCText} from "../field/dfcText";

export const DFCAddress = (props) => {

    const {GetDataFromServer,countries} = useContext(UtilitiesContext);

    const [geocodingAPIKey, setGeocodingAPIKey] = useState();

    const [data, setData] = useState(DATA_VALUES.NULL);
    const [currentValueCountry, setCurrentValueCountry] = useState(DATA_VALUES.NULL);
    //google search suggestions

    useEffect(() => {
        if(props.isEditing)
            GetDataFromServer('/system/geocodeapikey', setGeocodingAPIKey);
    }, [props.isEditing]);

    useEffect(() => {
        if (props.data && props.data !== DATA_VALUES.LOADING && data === DATA_VALUES.NULL) {
            const _addressData = _.get(props.data, props.accessor);
            setData(_addressData);
        }
    }, [props.data]);

    useEffect(() => {
        if(data && props.isEditing){
            const _tempData = {...props.data};
            _.set(_tempData, props.accessor, data);
            props.setData(_tempData);
            if(props.onChange)
                props.onChange();
        }
    },[data]);

    useEffect(() => {
        if (data && countries !== DATA_VALUES.LOADING) {
                setCurrentValueCountry(countries?.find(x => x.name === _.get(data, "country")));
        }
    }, [data, countries, props.accessor]);

    const ClearPlaceSelected = (countryData) => {
        let _tempAddressData = {...data};
        let country = countryData?.country ?? _.get(data, `country`);

        _.set(_tempAddressData, `country`, country);
        _.set(_tempAddressData, `googlePlaceId`, "");
        _.set(_tempAddressData, `subLocality`, "");
        _.set(_tempAddressData, `locality`, "");
        _.set(_tempAddressData, `areaLevel1`, "");
        _.set(_tempAddressData, `areaLevel2`, "");
        _.set(_tempAddressData, `street1`, "");
        _.set(_tempAddressData, `street2`, "");
        _.set(_tempAddressData, `postCode`, "");

        setData(_tempAddressData);
    }

    const HandleStreetChange = (new_value) => {
        if (new_value) {
            let _tempData = {...props.data};
            _.set(_tempData, props.accessor + ".street1", new_value.target.value);
            props.setData(_tempData);
            toast(TOAST_SETTINGS.MESSAGES.UNSAVEDCHANGES, {id: TOAST_SETTINGS.IDS.UNSAVEDCHANGES});
        }
    }

    const OnPlaceSelected = (place, inputRef) => {
        if (!place?.place_id)
            return;
        let _tempAddressData = {...data};
        let street1 = "";
        let postcode = "";
        _.set(_tempAddressData, `googlePlaceId`, place.place_id);
        _.set(_tempAddressData, `subLocality`, "-");
        place.address_components?.forEach((component) => {
            const componentType = component.types[0];
            switch (componentType) {
                case "street_number": {
                    street1 = component.long_name;
                    break;
                }

                case "route": {
                    street1 += ` ${component.long_name}`;
                    break;
                }

                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }

                case "postal_code_suffix": {
                    postcode = `${postcode}-${component.long_name}`;
                    break;
                }
                case "locality":
                    _.set(_tempAddressData, `locality`, component.long_name);
                    break;
                case "administrative_area_level_1": {
                    _.set(_tempAddressData, `areaLevel1`, component.long_name);
                    break;
                }
                case "administrative_area_level_2": {
                    _.set(_tempAddressData, `areaLevel2`, component.long_name);
                    break;
                }
                case "sublocality_level_1": {
                    _.set(_tempAddressData, `subLocality`, component.long_name);
                    break;
                }
                case "country": {
                    _.set(_tempAddressData, `country`, component.long_name?.toLowerCase());
                    break;
                }
            }
        })
        inputRef.value = street1;
        _tempAddressData.street1 = street1;
        _tempAddressData.postCode = postcode;

        setData(_tempAddressData);
    }

    if(geocodingAPIKey === DATA_VALUES.LOADING)
        return <PreloaderComponent/>

    if (props.isEditing && !geocodingAPIKey)
        return (
            <PreloaderComponent small/>
        )

    return (
        geocodingAPIKey === DATA_VALUES.LOADING ? <PreloaderComponent small/> :
            <DFCField {...props} titleClassName={"text-medium"} renderChildren>
                <Row>
                    <Col xs={12} md={6}>
                        <DFCCountrySelect title={"Country"} isEditing={props.isEditing}
                            data={data} setData={setData} accessor={`country`} onChange={ClearPlaceSelected}
                                          optionValueAccessor={"name"} isNotField={props.isNotField}/>
                    </Col>
                    <Col xs={0} md={6}></Col>

                    <Col xs={12} md={6}>
                        {
                            props.isEditing?
                                <>
                                    <label>Street Address</label>
                                    <GoogleAutoComplete apiKey={geocodingAPIKey}
                                                        isEditing={data?.country != null}
                                                        onInput={(e) => {
                                                            e.preventDefault();
                                                            HandleStreetChange(e)
                                                        }} onPlaceSelected={OnPlaceSelected}
                                                        value={data?.street1}
                                                        options={{
                                                            types: ["address"],
                                                            fields: ["address_component", "place_id"],
                                                            componentRestrictions: {country: [currentValueCountry?.shortName?.toLowerCase() ?? "bd"]}
                                                        }}
                                    />
                                </>:
                            <DFCText data={data} title={"Street Address"} accessor={"street1"} isNotField={props.isNotField}/>
                        }
                    </Col>

                    <Col xs={12} md={6}>
                        <DFCText title={"Apartment"} accessor={`street2`} data={data}
                                 setData={setData}
                                 isEditing={props.isEditing} placeholder={"ex: Apt C23"} isNotField={props.isNotField}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <DFCText required={true} title={"City"} accessor={`locality`} data={data}
                                 setData={setData} placeholder={"ex: Dhaka, Miami, Toronto"}
                                 isEditing={props.isEditing} isNotField={props.isNotField}/>
                    </Col>

                    <Col xs={12} md={6}>
                        <DFCText title={"Area"} accessor={`subLocality`} data={data}
                                 setData={setData}
                                 placeholder={"ex: Dhanmondi, Mirpur, Mohammadpur"}
                                 isEditing={props.isEditing} isNotField={props.isNotField}/>
                    </Col>


                    <Col xs={6} md={6}>
                        <DFCText title={"State"} accessor={`areaLevel1`} data={data}
                                 setData={setData}
                                 placeholder={"optional.."}
                                 isEditing={props.isEditing} isNotField={props.isNotField}/>
                    </Col>

                    <Col xs={6} md={6}>
                        <DFCText title={"County/District"} accessor={`areaLevel2`} data={data}
                                 setData={setData}
                                 isEditing={props.isEditing} isNotField={props.isNotField}/>
                    </Col>

                    <Col xs={6} md={6}>
                        <DFCText required={true} title={"Post Code"} accessor={`postCode`} data={data}
                                 setData={setData} placeholder={"ex: 33409"}
                                 isEditing={props.isEditing} isNotField={props.isNotField}/>
                    </Col>
                </Row>
                <hr/>
            </DFCField>
    );
}

export const GoogleAutoComplete = (props) => {
    const {ref} = usePlacesWidget({
        apiKey: props.apiKey,
        onPlaceSelected: props.onPlaceSelected,
        options: props.options,
    });


    return (
        <>
            <span className="text-danger text-large">*</span>
            <input ref={ref} className={"form-control"}
                   value={props.value}
                   onInput={props.onInput} autoComplete={"off"}
            />
        </>
    )
}

