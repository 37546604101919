import React, { useContext, useEffect, useState } from 'react';
import { AuthorizationContext } from '../../providers/authorizationProvider';
import {AWSCONSTANTS, DATA_VALUES, VALID_VALUES, VIEWTITLE_VALUES} from '../../utils/constants';
import { ApplicantProfileSubView } from '../applicant/applicantProfileSubView';
import {AdminProfileSubView} from "../admin/adminProfileSubView";
import {PreloaderComponent} from "../../components/preloaderComponent";
import {UtilitiesContext} from "../../providers/utilitiesProvider";

export const ProfileSelectorView = ( props ) =>
{
    const { GetUserAttribute, currentUser, } = useContext( AuthorizationContext );
    const { setDynamicViewTitleHTML } = useContext( UtilitiesContext );
    const profileType = GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILETYPE);

    useEffect(() => {
        setDynamicViewTitleHTML(VIEWTITLE_VALUES.UNSET);
    }, []);

    if(currentUser === DATA_VALUES.LOADING)
        return <PreloaderComponent/>

    return (
       <>
           {!profileType && <div className={"badge bg-danger"}>No profile type</div>}
           { (profileType === VALID_VALUES.PROFILETYPES.APPLICANT) && <ApplicantProfileSubView applicantId={GetUserAttribute("profile")} />}
           { (profileType === VALID_VALUES.PROFILETYPES.ADMIN) && <AdminProfileSubView/>}
       </>
    )
}