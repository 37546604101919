import React from 'react';
import _ from 'lodash'
import {DFCField} from "./dfcField";

export const DFCLink = (props ) =>
{
    return (
        <DFCField {...props} value={<a target={props.target} className="text-medium text-decoration-underline" href={props.href??_.get(props.data,props.href)}>
            <i className={`bi bi-${props.target==='_blank'?"box-arrow-up-right":""}`}></i> {props.text}
        </a>}>
        </DFCField>
    );
}