import React, {useState} from 'react';
import {DFCForm} from "../../components/dfc/dfcForm";
import {AdminCreateInstitutionForm} from "../../view_forms/admin/adminCreateInstitutionForm";


export const AdminCreateInstitutionView = (props) => {



  const [createInstitutionRequest, setCreateInstitutionRequest] = useState({
    requestedInstitution: {}
  });

  return(
      <DFCForm data={createInstitutionRequest.requestedInstitution}
               title={"Create Institution Profile"} saveDataUrl={"/institution/index"} saveHttpMethod={"post"}
          className={"d-none"} formContent={AdminCreateInstitutionForm} isEditing={true} editWithModal={true}
               LoadData={()=>window.location.href="/"}
      />
  )
}