import React, {useContext, useEffect, useState} from "react";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {VIEWTITLE_VALUES} from "../../utils/constants";
import {Button, Col, Container, Row} from "react-bootstrap";
import {PreloaderComponent} from "../../components/preloaderComponent";
import {useParams, useHistory} from "react-router-dom";
import {UpdateProfileInstructions} from "../profile/updateProfileInstructions";

export const ApplyView = (props) => {
    const params = useParams();
    const history = useHistory();
    const programId = params.programId;
    const termId = params.termId;

    const {setDynamicViewTitleHTML, SaveDataToServerWithoutConfirmation} = useContext(UtilitiesContext);
    const [hasFailed, setHasFailed] = useState(false);
    const [applicationResponseData, setApplicationResponseData] = useState();

    useEffect(() => {
        setDynamicViewTitleHTML(VIEWTITLE_VALUES.EMPTY);
        SaveDataToServerWithoutConfirmation(`/application/index/${programId}/${termId}`, 'post', null, setApplicationResponseData, false, null, () => {
            setHasFailed(true)
        }, () => {
            setHasFailed(true)
        });
    }, []);

    useEffect(() => {
        if (applicationResponseData?.id) {
            window.location.href = `/applications/${applicationResponseData.id}`
        }
    }, [applicationResponseData]);

    return (
        <Container className="mt-5">
            {
                hasFailed ?
                    <>
                        <Row className="mt-5 place-content-center w-100">
                            <h3 className="badge bg-danger text-light text-shadow p-3 text-medium">An Error has occurred. Please Click to Go back to Program</h3>
                        </Row>
                        <Row className="mt-5 place-content-center w-100">
                            <Button onClick={history.goBack}>Back to Program</Button>
                        </Row>
                    </>
                    :
                    <>
                        <PreloaderComponent/>
                        Taking you to your application...
                    </>
            }
        </Container>
    )
}