import React, {useContext, useEffect, useState} from "react";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {DFCForm} from "../../components/dfc/dfcForm";
import {AdminInfluencerReferralCreationForm} from "../../view_forms/account/adminInfluencerReferralCreationForm";
import {VIEWTITLE_VALUES} from "../../utils/constants";
export const AdminCreateInfluencersView = () => {
    const [data, setData] = useState({});
    const { setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    useEffect(() => {
        setDynamicViewTitleHTML(VIEWTITLE_VALUES.EMPTY);
    }, []);

    return (
        <ResponsiveContainerComponent>
            <DFCForm className={"d-none"} title={"Convert an applicant into an influencer"} isEditing={true} editWithModal
                     data={data} setData={setData} saveHttpMethod={"post"}
                     saveDataUrl={"/applicant/influencer"}
                     formContent={AdminInfluencerReferralCreationForm} LoadData={()=>window.location.href="/admin/applicants"}
            />
        </ResponsiveContainerComponent>
    )
}