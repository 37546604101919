import React, { useContext, useEffect, useState } from 'react';
import {Col, Row} from "react-bootstrap";
import {DFCCheckbox} from "../../components/dfc/field/dfcCheckbox";
import {DFCText} from "../../components/dfc/field/dfcText";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {DFCSelect} from "../../components/dfc/field/dfcSelect";
import {DFCAddress} from "../../components/dfc/presets/dfcAddress";
import { FIELDTYPES } from '../../utils/constants';

export const AdminCreateInstitutionForm = (props) =>
{
    const {GetDataFromServer} = useContext(UtilitiesContext);

    //enum options
    const [institutionLevelEnumOptions, setInstitutionLevelEnumOptions] = useState([]);
    const [institutionTypeEnumOptions, setInstitutionTypeEnumOptions] = useState([]);

    useEffect(() => {
        GetDataFromServer('/system/enumchoices/InstitutionLevel', setInstitutionLevelEnumOptions);
        GetDataFromServer('/system/enumchoices/InstitutionType', setInstitutionTypeEnumOptions);
    }, []);

    if(!props.data)
        return <div>No data</div>

    return (
        <>
            <Col xs={8}>
                <DFCText title={"Institution Name"} accessor={`name`} data={props.data} required
                         setData={props.setData}
                         isEditing={props.isEditing}
                />
            </Col>
            <Col xs={4}>
                <DFCText title={"Institution Short Name"} accessor={`shortName`} data={props.data} required
                    setData={props.setData}
                    isEditing={props.isEditing}
                />
            </Col>
            <Col xs={12}>
                <DFCText required={true}
                         instructions={"Institution's primary email for Applystart communication. Note: This is visible to the students and" +
                             " may not be changed once created"} title={"Institution Email"} placeholder={"ex: admin@university.edu"}
                         accessor={`institutionEmail`} data={props.data}
                         setData={props.setData}
                         isEditing={props.isEditing}
                />
            </Col>
            <Col xs={12}>
                <DFCSelect required={true} title={"Institution Level"} isEditing={props.isEditing}
                         accessor={"level"}
                         data={props.data} setData={props.setData} options={institutionLevelEnumOptions}
                />
            </Col>
            <Col xs={12}>
                <DFCSelect required={true} title={"Institution Type"} isEditing={props.isEditing}
                         accessor={"type"}
                         data={props.data} setData={props.setData} options={institutionTypeEnumOptions}
                />
            </Col>
            <Col xs={12}>
                <DFCText required={true} title={"Institution Details"} isEditing={props.isEditing}
                    accessor={"details"}
                    data={props.data} setData={props.setData} type={FIELDTYPES.TEXTAREA}
                />
            </Col>
            <Col xs={12}>
                <DFCAddress data={props.data} setData={props.setData} isEditing={props.isEditing}
                            accessor={"address"}
                            title={"Institution Address"}/>
            </Col>
        </>
    );
}