import React, {useContext, useEffect, useState} from "react";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {Button, Col, Row} from "react-bootstrap";
import {DFCText} from "../../components/dfc/field/dfcText";
import {DFCSelect} from "../../components/dfc/field/dfcSelect";
import {DFCCheckbox} from "../../components/dfc/field/dfcCheckbox";
import {AWSCONSTANTS, DATA_VALUES, FIELDTYPES, VALID_VALUES} from "../../utils/constants";
import Tippy from "@tippyjs/react";
import {DFCList} from "../../components/dfc/dfcList";
import {InstitutionProgramRequirementForm} from "../institutions/institutionProgramRequirementForm";
import {AuthorizationContext} from "../../providers/authorizationProvider";
import {Link} from "react-router-dom";
import {DFCLink} from "../../components/dfc/field/dfcLink";
import {PreloaderComponent} from "../../components/preloaderComponent";

export const InstitutionProgramForm = (props) => {
    const {GetDataFromServer, SaveDataToServer} = useContext(UtilitiesContext);
    const {GetUserAttribute} = useContext(AuthorizationContext);
    const [educationLevelEnumOption, setEducationLevelEnumOption] = useState();

    const IsAdmin = GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILETYPE) === VALID_VALUES.PROFILETYPES.ADMIN;


    useEffect(() => {
        if (props.isEditing)
            GetDataFromServer('/system/enumchoices/EducationLevel', setEducationLevelEnumOption);
    }, [GetDataFromServer, props.isEditing]);

    function ReloadPage() {
        window.location.reload()
    }

    function UpdateOpenForTerm(programTerm, currentValue) {
        programTerm.open = !currentValue;
        SaveDataToServer(`/program/list/${props.data?.id}/ProgramTerms`, 'put', programTerm, ReloadPage, false);
    }

    const _colSizeMd = 6;
    return (
        <>

            {(props.isEditing) &&
                <>
                    <Col xs={12} md={_colSizeMd}>
                        <DFCText title={"Name"} accessor={`name`} required={true} placeholder={"ex: Bachelor of Arts"}
                                 data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField/>
                    </Col>
                </>

            }
            <Col md={(props.isViewingDetails || props.isEditing) ? 9 : 12}>
                <Row>
                    <Col xs={12} md={12}>
                        {
                            props.data?.institution === DATA_VALUES.LOADING?
                                <div className={"w-50"}><PreloaderComponent small/> </div>:
                                <DFCLink required={true} title={"Institution"} text={props.data?.institution?.name}
                                         href={`/institutions/${props.data?.institutionId}`}
                                         isNotField/>
                        }


                    </Col>
                    <Col xs={12} md={_colSizeMd}>
                        <DFCSelect required={true} title={"Program Type"} accessor={`educationLevel`}
                                   data={props.data} setData={props.setData} options={educationLevelEnumOption}
                                   isEditing={props.isEditing} isNotField/>

                    </Col>

                    <Col xs={12} md={_colSizeMd}>
                        <DFCText title={"Department"} accessor={`department`} textTruncate
                                 data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField/>
                    </Col>

                    <Col xs={12} md={_colSizeMd}>
                        <DFCText title={"Length"} accessor={`length`} required={true}
                                 data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField/>
                    </Col>

                    <Col xs={12} md={_colSizeMd}>
                        <DFCText title={"Application Fee"} accessor={`applicationFee`} as={"textarea"}
                                 data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField/>


                    </Col>


                    <Col xs={12} md={12}>
                        <DFCText title={"Tuition Fee (Estimated)"} accessor={`tuitionFee`} as={"textarea"}
                                 data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField/>


                    </Col>
                </Row>
            </Col>
            {
                (props.isViewingDetails) &&
                <Col span xs={12} md={3}>
                    <label>Available Terms</label>
                    {props?.data?.programTerms?.filter(x=>!x.deprecated)?.map((item, index) =>
                        <Row key={index} style={{paddingBottom: "5px"}} className={"align-items-center"}>
                            <Col>
                                <span>{item.term.name}</span>
                            </Col>
                            <Col>
                                {
                                    IsAdmin && props.isEditing === true ?
                                        <>
                                            <div style={{display: "flex", alignItems: "center"}}
                                                 className={"text-dark"}>
                                                <div style={{paddingRight: "0.3rem", fontWeight: 600}}>Open:</div>
                                                <DFCCheckbox title={""} data={item.open}
                                                             setData={() => UpdateOpenForTerm(item, item.open)}
                                                             defaultValue={item.open}
                                                             isEditing={true}
                                                />
                                            </div>
                                        </>
                                        :
                                        item.open === true ?
                                            <Link to={`/apply/${props.data.id}/${item.id}`}>
                                                <Button variant={"success"} className={"float-center"}
                                                        size={"sm"}>APPLY</Button>
                                            </Link>
                                            :
                                            <Tippy
                                                content={"This program is not open for this term. Please check back later."}>
                                                <div className={"float-center"}>
                                                    <Button disabled={true} variant={"tertiary"}
                                                            size={"sm"}>APPLY</Button>
                                                </div>
                                            </Tippy>
                                }
                            </Col>
                        </Row>
                    )}
                </Col>
            }
            {
                (props.isViewingDetails || props.isAdding) ?
                    <>
                        <Col xs={12} md={9}>
                            <DFCText required={true} title={"Details"} accessor={`details`} as={"textarea"}
                                     data={props.data} setData={props.setData} isEditing={props.isEditing}
                                     type={FIELDTYPES.TEXTAREA} isNotField/>
                        </Col>

                        {
                            !props.isAdding && props.data &&
                            <Col xs={12}>
                                <DFCList headerClassName={"py-0"} sortFunction={(x, y) => x.name > y.name ? 1 : -1}
                                         title={`Requirements for ${_.get(props.data, "name")}`}
                                         headerContent={InstitutionProgramRequirementForm}
                                         formContent={InstitutionProgramRequirementForm}
                                         hideBody
                                         getListDataUrl={`/program/list/${props.data.id}/Requirements`}
                                         addDataUrl={`/program/list/${props.data.id}/Requirements`}
                                         editDataUrl={`/program/list/${props.data.id}/Requirements`}
                                         deleteDataUrl={`/program/list/${props.data.id}/Requirements`}
                                         isEditable={props.isEditable}
                                />
                            </Col>
                        }
                    </>
                    :
                    <Col xs={3} className={"m-auto"}>
                        <DFCLink text="Details" href={`/programs/${props.data?.id}`} isNotField/>
                    </Col>
            }
        </>
    )
}
