import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import toast from "react-hot-toast";
import {DATA_VALUES, TOAST_SETTINGS} from "../../utils/constants";
import {Col, ListGroup, Row, Form, FormFile, Card, Button} from "react-bootstrap";
import {PreloaderComponent} from "../preloaderComponent";
import Tippy from "@tippyjs/react";
import {FormatSelectOptionFromValue, GetFormattedString} from "../../utils/helpers";

export const DFCDocuments = (props) => {
    const {GetDataFromServer, SaveDataToServer, SaveDataToServerWithoutConfirmation} = useContext(UtilitiesContext);

    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [addableDocumentCategories, setAddableDocumentCategories] = useState([]);

    const LoadData = () => {

        if (props.getListDataUrl && !props.getListDataUrl.includes("undefined")) {
            GetDataFromServer(props.getListDataUrl, setData);
        }

        toast.dismiss(TOAST_SETTINGS.IDS.UNSAVEDCHANGES);
        setIsLoading(false);

    };

    useEffect(() => {
        LoadData();
    }, [props.getListDataUrl, GetDataFromServer, props.data, props.documentCategories]);

    useEffect(() => {
        if(data && data !== DATA_VALUES.LOADING &&  props.documentCategories)
        {
            const _tempCategories = [];
            props.documentCategories.forEach(item=>
                {
                    if(!data.some(x=>x.category === item.value))
                        _tempCategories.push(item);
                }
            )
            setAddableDocumentCategories(_tempCategories);
        }
    }, [data]);

    const DeleteDocument = (item) => {
        SaveDataToServer(props.deleteDataUrl, 'delete', item, null, false, LoadData);
    };

    const SaveDocument = (fileToAdd, category) => {
        if (!fileToAdd) {
            toast.error("invalid document!")
            return;
        }

        //upload the document
        setIsLoading(true);
        const fileUploadRequest = new FormData;
        fileUploadRequest.append("file", fileToAdd);
        fileUploadRequest.append("category", category);
        if (props.bucketName && props.keyPrefix) {
            fileUploadRequest.append("bucketName", props.bucketName);
            fileUploadRequest.append("keyPrefix", props.keyPrefix);
        }

        SaveDataToServerWithoutConfirmation(props.addDataUrl, "post", fileUploadRequest, null, false, LoadData, null, null, "multipart/form-data");
    }

    const hiddenDownloadButton = useRef();
    const [fileToDownload, setFileToDownload] = useState();
    const DownloadDocument = (documentId)=>{
        GetDataFromServer(`/document/index/${documentId}`, setFileToDownload);
        toast.loading('waiting for download', {id: TOAST_SETTINGS.IDS.FILE});

    }

    useEffect(() => {
        if(fileToDownload && fileToDownload !== DATA_VALUES.LOADING && fileToDownload.link){
            hiddenDownloadButton.current.href = fileToDownload.link;
            hiddenDownloadButton.current.click();
            toast.success( "success", { id: TOAST_SETTINGS.IDS.FILE } );
        }
    }, [fileToDownload]);

    if(!data)
        return (<div>no data..</div>);

    if (data === DATA_VALUES.LOADING || isLoading)
        return (
            <PreloaderComponent small/>
        )


    return (
        <Card className={"border-0 shadow-none"}>
            <Card.Header className={"bg-primary-light text-light"}>
                <h5>
                    <i className={"bi bi-file-earmark mr-2"}></i>
                    Documents
                    {
                        props.instructions && <Tippy content={props.instructions}>
                            <i className={"bi bi-info-square text-muted ml-1 text-medium"}></i>
                        </Tippy>
                    }
                </h5>
            </Card.Header>
            <Card.Body className={"p-0"}>
                <a className={"d-none"} ref={hiddenDownloadButton} download>Download</a>
                <ListGroup variant="flush">

                    {
                        data?.
                        sort((x,y)=>x.category?.toLowerCase()<y.category?.toLowerCase()?-1:1)
                        .sort((x,y)=>y.category.includes("required")-x.category.includes("required")).map((item, index) =>
                            <ListGroup.Item key={index}>
                                <Row>
                                    <Col xs={12} md={5} >
                                        {
                                            <div>{GetFormattedString(item.category)}</div>
                                        }
                                    </Col>
                                    <Col xs={10} md={5} className={"align-self-center"}>
                                        <Button variant={"link"} className={"text-secondary text-left text-truncate w-100 "} target={"_blank"}
                                            onClick={()=>DownloadDocument(item.id)}>
                                            <i className={"bi bi-paperclip font-weight-bolder mr-2"}></i>
                                            {item.name}
                                        </Button>
                                    </Col>
                                    <Col xs={2}>
                                        {
                                            (props.isEditing) && props.deleteDataUrl &&
                                            <Tippy content={"Delete document"}>
                                                <button className="d-inline btn btn-outline-danger btn-sm p-0"
                                                        onClick={() => DeleteDocument(item)}>
                                                    <i className="bi bi-trash m-1 text-gray "></i>
                                                </button>
                                            </Tippy>
                                        }
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>

                <hr/>
                {
                    // Adding rows
                    props.isEditing && addableDocumentCategories
                        .sort((x,y)=>x.value?.toLowerCase()<y.value?.toLowerCase()?-1:1)
                        .sort((x,y)=>y.value?.includes("required")-x.value.includes("required"))
                        .map((item)=>
                        <Row className={"mx-1 py-2 border-bottom"}>
                            <Col xs={12} md={5} className={"py-0 my-0"}>
                                {
                                    <div>{item.label}</div>
                                }
                            </Col>
                            <Col xs={12} md={5} className={"align-self-center"}>
                                <Form>
                                    <Form.File
                                        onChange={(e) => SaveDocument(e.target.files[0], item.value)}
                                        label={"Upload File"}
                                        custom
                                    />
                                </Form>
                            </Col>
                        </Row>
                    )
                }
            </Card.Body>
        </Card>
    );
}