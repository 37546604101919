import React, { useContext, useState, useEffect } from 'react';
import { ApplicationsForm } from '../../view_forms/applications/applicationsForm';
import {DATA_VALUES, FILTER_TYPES, VIEWTITLE_VALUES} from '../../utils/constants';
import { UtilitiesContext } from '../../providers/utilitiesProvider';
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {Row} from "react-bootstrap";
import {DFCFilter} from "../../components/dfc/dfcFilter";
import {DFCForm} from "../../components/dfc/dfcForm";
import {AuthorizationContext} from "../../providers/authorizationProvider";

export const ApplicationsView = (  ) =>
{
    const { setDynamicViewTitleHTML } = useContext( UtilitiesContext );
    const [ showAll, setShowAll ] = useState( false );
    const {GetUserAttribute} = useContext(AuthorizationContext);

    const ToggleViewAll = () =>
    {
        setShowAll(!showAll);
    }

    useEffect( () =>
    {
        setDynamicViewTitleHTML( VIEWTITLE_VALUES.EMPTY )
    }, [] );

    const filterCategories =
        [
            {
                // need identifier and prefix expression
                name: GetUserAttribute("custom:profile_type") === "applicant" ? "University Name" : "Student Name",
                filterType: FILTER_TYPES.SEARCH,
                expression: GetUserAttribute("custom:profile_type") === "applicant" ? "data.Program.Institution.Name.ToLower().Contains({$value})" : "data.OriginalApplicant.Name.ToLower().Contains({$value})"
            },
            {
                // need identifier and prefix expression
                name: "ID",
                filterType: FILTER_TYPES.SEARCH,
                expression: `data.DisplayIndex == {$value}`
            }
        ]

    return (
        <ResponsiveContainerComponent>
            <Row className="m-2 text-right w-100 justify-content-end sticky-top top-navsize">
                {!showAll ?
                    <div className="btn btn-primary" onClick={ToggleViewAll}>Show All Applications</div> :
                    <div className="btn btn-primary" onClick={ToggleViewAll}>Show Active Applications</div>
                }
            </Row>
            <DFCFilter
                title={"Search Applications"}
                getDataUrl={`/application/index?include=Program.Institution&showAll=${showAll}`}
                categories={filterCategories}
                content={ApplicationsFilteredView}
                takePerPage={20}
            />
        </ResponsiveContainerComponent>
    )
}

const ApplicationsFilteredView = (props) =>{
    return (
        <Row>
            {
                props.data !== DATA_VALUES.NULL && props.data !== DATA_VALUES.LOADING &&
                props.data?.map((item) =>
                    <DFCForm className={"w-100"} formContent={ApplicationsForm} data={item} title={`APPLICATION ID ${item.displayIndex}`}/>
                )

            }
        </Row>
    )
}