import { DFCList } from "../../components/dfc/dfcList";
import React, { useContext, useEffect } from "react";
import { ResponsiveContainerComponent } from "../../components/responsiveContainerComponent";
import { AdminManageTermsForm } from "../../view_forms/admin/adminManageTermsForm";
import { AdminManageTermsHeaderContent } from "../../view_forms/admin/adminManageTermsHeaderContent";
import { UtilitiesContext } from "../../providers/utilitiesProvider";

export const AdminManageTermsView = () => {
    const { setDynamicViewTitleHTML } = useContext(UtilitiesContext);
    useEffect(() => {
        setDynamicViewTitleHTML("Terms");
    }, []);

    return (
        <ResponsiveContainerComponent>
            <DFCList title={"Manage Terms"} isEditable={true} hideBody={true}
                getListDataUrl={"/term/index"}
                editDataUrl={"/term/index"}
                addDataUrl={"/term/index"}
                headerContent={AdminManageTermsHeaderContent} formContent={AdminManageTermsForm}
            />
        </ResponsiveContainerComponent>
    )
}