import React from 'react';
import {ToastBar, Toaster, toast} from 'react-hot-toast';

// app-wide imports

// styles

import './style/styles.scss';


import {Routes} from './routes';
import {UtilitiesProvider} from './providers/utilitiesProvider';
import {AuthorizationProvider} from './providers/authorizationProvider';
import "./global";

const App = () => {

    return (
        <div className="App">
            <Toaster position={"bottom-left"}/>
            <AuthorizationProvider>
                <UtilitiesProvider>
                    <Routes/>
                </UtilitiesProvider>
            </AuthorizationProvider>
        </div>
    );
}

export default App;