import React, { useContext, useEffect } from 'react';
import { UtilitiesContext } from '../providers/utilitiesProvider';
import {Row} from "react-bootstrap";
import {VIEWTITLE_VALUES} from "../utils/constants";

export const NotFoundView = (props) =>
{
    const { setDynamicViewTitleHTML } = useContext( UtilitiesContext );

    useEffect( () =>
    {
        setDynamicViewTitleHTML( VIEWTITLE_VALUES.EMPTY );
    });

    return (
        <Row className="mt-5 place-content-center w-100">
            <h3 className="badge bg-danger text-light text-shadow p-3 text-medium">Requested Page or Resource does not exist</h3>
        </Row>
    )
}