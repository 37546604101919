import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {AWSCONSTANTS, DATA_VALUES, VALID_VALUES} from "../../utils/constants";
import {DFCForm} from "../../components/dfc/dfcForm";
import {InstitutionProgramForm} from "../../view_forms/programs/institutionProgramForm";
import {AuthorizationContext} from "../../providers/authorizationProvider";

export const ProgramView = (props)=>{
    const params = useParams();
    const {GetDataFromServer, setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    const {GetUserAttribute} = useContext(AuthorizationContext)
    const [programData, setProgramData] = useState(DATA_VALUES.LOADING);

    const programId = params.id;
    const IsAdmin = GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILETYPE) === VALID_VALUES.PROFILETYPES.ADMIN;
    const isEditable =IsAdmin && !programData?.institution?.goLive;


    const LoadProgram = () => {
        if (programId) {
            GetDataFromServer(`/program/index/${programId}`, setProgramData);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    useEffect(() => {
        LoadProgram();
    }, []);

    useEffect(()=>{
        if(programData && programData !== DATA_VALUES.LOADING)
            setDynamicViewTitleHTML(`Program ID ${programData.displayIndex}: ${programData.name}`)
    },[programData, setDynamicViewTitleHTML]);


    return (
        <ResponsiveContainerComponent>
            <DFCForm getDataUrl={`/program/index/${programId}?include=ProgramTerms.Term,Institution`}
                     saveDataUrl={`/program/index`} saveHttpMethod={"put"}
                     isEditable={isEditable}
                     formContent={InstitutionProgramForm} isViewingDetails={true}
            />
        </ResponsiveContainerComponent>
    )
}