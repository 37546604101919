import {Col} from "react-bootstrap";
import {DFCText} from "../../components/dfc/field/dfcText";
import {FIELDTYPES} from "../../utils/constants";
import {DFCAddress} from "../../components/dfc/presets/dfcAddress";
import {DFCDocuments} from "../../components/dfc/dfcDocuments";
import {useState} from "react";

export const InstitutionSummaryForm = (props) => {
    const [institutionCoverImageDocumentCategory] = useState([{label:"Institution Cover Image", value:"Institution_Cover_Image"}]);

    return (
        <>
            <Col xs={12}>
                {
                    props.isEditing &&
                    (
                        <>
                            <DFCText accessor={"details"} title={"Summary"} required={true} isEditing={props.isEditing}
                                     data={props.data} setData={props.setData}
                                     type={FIELDTYPES.TEXTAREA}
                            />

                            <DFCText accessor={"shortName"} title={"Colloquial Name"} required={true} isEditing={props.isEditing}
                                     data={props.data} setData={props.setData}
                            />

                            <DFCDocuments title={"Cover Photo"}
                                          getListDataUrl={`/institution/list/${props.data?.id}/Photos`}
                                          addDataUrl={`/institution/Photos/${props.data?.id}`}
                                          deleteDataUrl={'/document/index'} documentCategories={institutionCoverImageDocumentCategory}
                                          isEditing={props.isEditing} maxAttachments={1}
                            />
                        </>
                    )
                }
                <DFCAddress title={"Mailing Address"} accessor={"mailingAddress"} isNotField
                    data={props.data} setData={props.setData} isEditing={props.isEditing} />
            </Col>
        </>
    )
}