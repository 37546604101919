import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AuthorizationContext} from '../../providers/authorizationProvider';
import {Col, Navbar as BsNavbar, Row, Image, Container, Badge} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import Tippy from "@tippyjs/react";
import {UserSubComponent} from "./userSubComponent";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {AWSCONSTANTS, DATA_VALUES, VALID_VALUES} from "../../utils/constants";
import { LOOKER_REPORTIDS, ROUTE_VALUES} from "../../configs";

class NavRoute {
    constructor(path, label, bsIconClass) {
        this.path = path;
        this.label = label;
        this.bsIconClass = bsIconClass;
    }
}

const _referralPath = "/account/refer";

export const NavbarComponent = (props) => {
    const [applicant, setApplicant] = useState();
    const {currentUser} = useContext(AuthorizationContext);
    const [notifications, setNotifications] = useState(DATA_VALUES.NULL);

    const loadNotifications = useCallback(() => {
        if ( currentUser && currentUser !== DATA_VALUES.NULL_USER && !notifications ) GetDataFromServer("/Notification/Index", setNotifications);
    }, [currentUser]);

    const [navRoutes, setNavRoutes] = useState([
        new NavRoute("/profile", "Profile", "person"),
        new NavRoute(ROUTE_VALUES.APP_HOMEPAGE, "Universities and Colleges", "menu-button-wide"),
        new NavRoute("/applications", "Applications", "file-earmark-medical"),
        new NavRoute(_referralPath, "Refer a Friend", "megaphone")
    ]);

    const location = useLocation();
    const {dynamicViewTitleHTML, GetDataFromServer} = useContext(UtilitiesContext);
    const {GetUserAttribute} = useContext(AuthorizationContext);

    useEffect(() => {
        if (GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILETYPE) === VALID_VALUES.PROFILETYPES.APPLICANT) {
            const applicantProfileId = GetUserAttribute("profile");
            GetDataFromServer(`/applicant/index/${applicantProfileId}`, setApplicant);
        }
    }, [GetUserAttribute]);

    useEffect(() => {
        loadNotifications();
    }, [currentUser]);


    return (
        <div className={"fixed-top"} >
            <BsNavbar bg="light"  className={"p-0 pt-1 navbar"} >
                <Container fluid={"xl"}>
                    <Row className={"m-0 w-100"}>
                        <Col xs={6} md={3} className={"order-0 p-1"}>
                            <BsNavbar.Brand href={ROUTE_VALUES.APP_HOMEPAGE} className={"m-0"}>
                                <img src={require("../../graphics/logo/applystart-logo-black-svg-width.svg").default}
                                     className="img-fluid w-100" alt="applystart-logo"/>
                            </BsNavbar.Brand>
                        </Col>
                        <Col xs={12} md={7} className={"order-2 order-md-1"}>
                            {/*<Row className={"m-1"}>*/}
                                {
                                    navRoutes.map((item, index) =>
                                        (item.path !== _referralPath || applicant != null) && //referral path is only for applicants
                                            <div className={"d-inline-block mx-md-2"} key={index}>
                                                <Link to={item.path}>
                                                    <Tippy content={item.label}>
                                                        <div
                                                            className={"nav-route m-2 text-center " + (location.pathname === item.path ? "selected" : "")}>
                                                            <i className={`text-large text-md-x-large bi bi-${item.bsIconClass}${location.pathname === item.path ? "-fill" : ""}`}></i>
                                                        </div>
                                                    </Tippy>
                                                </Link>
                                            </div>
                                    )
                                }

                                {
                                    <div className={"d-inline-block  mx-md-2"}>
                                        <a target={"_blank"}
                                            href={ROUTE_VALUES.COUNSELING_LINK}>
                                            <Tippy content="Book a Free Counseling Session">
                                                <div className={"nav-route m-2 text-center "}>
                                                    <i className={`text-large text-md-x-large bi bi-calendar4-event`}></i>
                                                </div>
                                            </Tippy>
                                        </a>
                                    </div>
                            }

                            {
                                <div className={"d-inline-block  mx-md-2"}>
                                    <a
                                        href={ROUTE_VALUES.PROGRAMS_PAGE}>
                                        <Tippy content="Find Programs">
                                            <div className={"nav-route m-2 text-center "}>
                                                <i className={`text-large text-md-x-large bi bi-search`}></i>
                                            </div>
                                        </Tippy>
                                    </a>
                                </div>
                            }

                                {
                                    applicant?.influencerInformation?.referralCode &&
                                    <div className={"d-inline-block mx-md-2"}>
                                        <a target={"_blank"}
                                           href={`https://lookerstudio.google.com/u/0/reporting/${LOOKER_REPORTIDS.INFLUENCER_DASHBOARD}/page/
                                         v4leD?params=${encodeURIComponent(`{"ds10.applicantId":"${applicant.id}","ds12.applicantId":"${applicant.id}"}`)}`}>
                                            <Tippy content="Influencer Dashboard">
                                                <div className={"nav-route m-2 text-center "}>
                                                    <i className={`text-large text-md-x-large bi bi-speedometer`}></i>
                                                </div>
                                            </Tippy>
                                        </a>
                                    </div>
                                }
                                {   currentUser != null && notifications !== DATA_VALUES.LOADING &&
                                        <div className={"d-inline-block mx-md-2"}>
                                            <Link to={ROUTE_VALUES.NOTIFICATIONS}>
                                                <Tippy content={"Notifications"}>
                                                    <div
                                                        className={"nav-route text-center "+ (location.pathname === ROUTE_VALUES.NOTIFICATIONS ? "selected" : "")}>
                                                        <i className="nav-icon bi bi-bell-fill text-large text-md-x-large"></i>
                                                        <Badge  className={"badge-danger"}>{notifications?.filter(x=>!x.deprecated).length}</Badge>
                                                    </div>
                                                </Tippy>
                                            </Link>
                                        </div>
                                }
                            {/*</Row>*/}
                        </Col>
                        <Col xs={6} md={2} className={"order-1 order-md-2 text-right align-self-center"}>
                            <UserSubComponent/>
                        </Col>
                    </Row>

                </Container>
            </BsNavbar>
            <Row className={"w-100 m-0 text-light font-weight-bold navbar-bg dynamic-title-nav"}>
                <Container fluid={"xl"} className={"px-3 py-2 text-truncate"}>
                    {dynamicViewTitleHTML}
                </Container>
            </Row>
        </div>
    )
}