import React from 'react';
import Tippy from '@tippyjs/react';
import {DATA_VALUES, FIELDTYPES} from "../../../utils/constants";
import {InputGroup, Row} from "react-bootstrap";

export const DFCField = (props) =>
{
    return (
        <div className={props.className+" text-dark"}>
            <Row className={"container"}>
                {props.labelIcon && <i className={`bi bi-${props.labelIcon} text-gray`}></i>}
                {props.title&& <label className={"form-label font-weight-bolder mt-1 "+props.titleClassName}>{props.title}</label>}
                {props.isEditing && props.required && <i className={`bi bi-asterisk text-extra-small text-danger`}></i>}
                {props.instructions &&
                    <Tippy content={props.instructions}>
                        <i className="bi bi-info-square text-gray ml-1"></i>
                    </Tippy>
                }
                {(props.required || props.instructions) && <br /> }
            </Row>


            <div className={props.className}>
                {props.isEditing || props.renderChildren ? props.children:
                   <>
                       <div className={"dfc-value "+(props.isNotField?"":"dfc-field")}>
                           <InputGroup hasValidation>
                                       {props.type === FIELDTYPES.WEBSITE && <InputGroup.Text> <i className="bi bi-box-arrow-up-right text-gray p-0 text-small"></i>   </InputGroup.Text>}
                                       {props.type === FIELDTYPES.EMAIL && <InputGroup.Text> <i className="bi bi-envelope-at text-gray p-0 text-small"></i>   </InputGroup.Text>}
                                       {props.type === FIELDTYPES.PHONE &&  <InputGroup.Text> <i className="bi bi-telephone text-gray p-0  text-small"></i>   </InputGroup.Text>}

                               {props.type === FIELDTYPES.WEBSITE?
                                   <a target={"_blank"} className={"text-medium ml-2 align-self-center text-decoration-underline"} href={props.value?.startsWith("http")?props.value:""}>{props.value?.startsWith("http")? new URL(props.value).origin:"link error"}</a>
                                       :
                                   <span className={`ml-2 align-self-center ${(props.textTruncate?"text-truncate":"")}`}>{props.value??DATA_VALUES.NODATA_HTML}</span>
                               }
                           </InputGroup>
                       </div>
                   </>
                }
            </div>
        </div>
    );
}