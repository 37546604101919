import React, {useEffect, useState} from 'react'
import {Card, Col, Row} from "react-bootstrap";
import {AWS_CONFIGS} from "../../configs";
import {Link} from "react-router-dom";
import Tippy from "@tippyjs/react";

export const InstitutionCard = ({institution})=>{
    const [coverImage, setCoverImage] = useState();
    useEffect(()=>{
        if(institution && institution?.photos?.length>0)
        {
            var sorted = institution.photos?.sort((x,y)=>new Date(x?.createdAt) - new Date(y?.createdAt));
            if(sorted?.length>0)
                setCoverImage(sorted[0]?.link);
        }
    },[institution]);

    return(
        <Col xs={12} md={4} className={"mb-2 h-100 m-auto"} >
            <Link to={`/institutions/${institution?.id}`} className={"text-decoration-none"}>
                <Card  className={"hover-card-scale hover-border institution-card  overflow-hidden mb-2"}>
                    <div className={"institution-card-image"} style={{backgroundImage:`url('${coverImage??`${AWS_CONFIGS.S3BUCKET_URL}/defaults/institution-banner.jpg`}')`}}></div>
                    {/*<Card.Img className={"img-fluid"} variant="top" src={coverImage??`${AWS_CONFIGS.S3BUCKET_URL}/defaults/institution-banner.jpg`} />*/}
                    <Card.Body>
                        <Tippy content={institution.name}>
                            <p className={"text-truncate"}>{institution.name}</p>
                        </Tippy>
                        <hr/>
                        <Card.Text className={"font-weight-light text-small"}>
                            {!institution.goLive ? <badge className={"badge badge-danger"}>Not Live</badge>:<></>} {institution.deprecated? <span className={"badge badge-dark"}>deprecated</span> : <></>}
                            <i className={"bi bi-geo-alt-fill text-primary"}></i> {institution.address?.locality}, {institution.address?.country}<br/>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Link>
        </Col>
    )
}