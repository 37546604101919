import React from 'react';
import _ from 'lodash'
import { Col, Row } from "react-bootstrap";

export const AdminManageTermsHeaderContent = (props) => {
    if (!props.data)
        return <div>No data</div>

    return (
        <Row className={"p-2 font-weight-bold"}>
            <Col xs={6} md={6}>
                <Row>
                    <Col xs={12}>
                        <span className={"text-secondary"}>{props.data?.name}</span>
                    </Col>
                    <Col xs={12}>
                        Deprecated: {props.data?.deprecated && <i className={"bi bi-check-circle-fill text-danger"}></i>}
                    </Col>
                </Row>
            </Col>
            <Col xs={6} md={6} className={""}>
                <Row className={""}>
                    <Col xs={12}>
                        From Date: { props.data?.fromDate }
                    </Col>
                    <Col xs={12}>
                        To Date: { props.data?.toDate }
                    </Col>
                </Row>

            </Col>
        </Row>
    );
}