import React, { useContext, useEffect, useState } from 'react';
import { DFCDateTime } from '../../components/dfc/field/dfcDateTime';
import { DFCSelect } from '../../components/dfc/field/dfcSelect';
import { DFCText } from '../../components/dfc/field/dfcText';
import { DATA_VALUES } from '../../utils/constants';
import { UtilitiesContext } from "../../providers/utilitiesProvider";
import { DFCCountrySelect } from '../../components/dfc/field/dfcCountrySelect';
import {Col} from "react-bootstrap";

export const ApplicantPersonalInformationForm = ( props ) =>
{
    const { GetDataFromServer } = useContext( UtilitiesContext );
    const [ nameTitleEnumOptions, setNameTitleEnumOptions ] = useState( DATA_VALUES.EMPTY_ARRAY );
    const [ genderEnumOptions, setGenderEnumOptions ] = useState( DATA_VALUES.EMPTY_ARRAY );

    useEffect( () =>
    {
        if(props.isEditing &&
            (nameTitleEnumOptions === DATA_VALUES.EMPTY_ARRAY || genderEnumOptions === DATA_VALUES.EMPTY_ARRAY)){
            GetDataFromServer( '/system/enumchoices/NamePrefixTitle', setNameTitleEnumOptions );
            GetDataFromServer( '/system/enumchoices/Gender', setGenderEnumOptions );
        }
    }, [ GetDataFromServer ] );

    const _colSizeMd = props.isEditing?6:3;
    return (
        <>
            <Col xs={12} md={_colSizeMd}>
                <DFCSelect isEditing={props.isEditing}
                           accessor={"title"} title={"Title/Prefix"}
                           required
                           data={props.data} setData={props.setData} options={nameTitleEnumOptions} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCText isEditing={props.isEditing}
                         accessor={"firstName"} title={"First Name"}
                         required
                         data={props.data} setData={props.setData} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCText isEditing={props.isEditing}
                         accessor={"middleName"} title={"Middle Name"}
                         data={props.data} setData={props.setData} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCText isEditing={props.isEditing}
                         accessor={"lastName"} title={"Last Name"}
                         required
                         data={props.data} setData={props.setData} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCDateTime isEditing={props.isEditing}
                    accessor={"birthday"} title={"Birthday"}
                    required
                    data={props.data} setData={props.setData}
                />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCSelect isEditing={props.isEditing}
                    accessor={"gender"} title={"Gender"}
                    required
                    data={props.data} setData={props.setData} options={genderEnumOptions} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCText isEditing={props.isEditing}
                         accessor={"birthCity"} title={"Birth City"}
                         required
                         data={props.data} setData={props.setData} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCCountrySelect isEditing={props.isEditing}
                    accessor={"nationality"} title={"Nationality"}
                    required
                    data={props.data} setData={props.setData} />
            </Col>
       
        </>
    )
}