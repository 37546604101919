import { useContext, useEffect, useState } from "react"
import { UtilitiesContext } from "../../../providers/utilitiesProvider"
import _ from 'lodash'
import { DFCField } from "./dfcField"
import Select, { components } from "react-select";
import { DATA_VALUES, TOAST_SETTINGS, } from "../../../utils/constants";
import { InfoToastContent } from "../../../utils/helpers";
import toast from 'react-hot-toast';

export const DFCCountrySelect = ( props ) =>
{
    const DFCCountryComponent = ( { data, innerProps} ) =>
    {
        return (
            <div className="p-1 rounded-1 hover-bg-secondary hover-text-light" {...innerProps}>
                <span className={`fi fi-${ data?.shortName } mr-2`}></span>
                {data[props.optionLabelAccessor??"name"]}
            </div>
        );
    }

    const {countries} = useContext(UtilitiesContext);
    const [ currentValue, setCurrentValue ] = useState( DATA_VALUES.NULL );


    useEffect( () =>
    {
        const _countryValueInData = _.get(props.data, props.accessor);
        if(props.optionValueAccessor && countries && countries !== DATA_VALUES.LOADING){
            const _country = countries?.find(x=>x[props.optionValueAccessor]?.toLowerCase() === _countryValueInData?.toLowerCase() );
            setCurrentValue(_country);
        }
        else setCurrentValue( _countryValueInData );
    }, [ props.data, props.accessor, countries,props.optionValueAccessor ] );


    const HandleChange = ( new_value ) =>
    {
        if ( new_value )
        {
            let _tempData = { ...props.data };
            _.set( _tempData, `${ props.accessor }`, props.optionValueAccessor?new_value[props.optionValueAccessor]:new_value );
            props.setData( _tempData );
            toast(TOAST_SETTINGS.MESSAGES.UNSAVEDCHANGES, { id: TOAST_SETTINGS.IDS.UNSAVEDCHANGES, duration: TOAST_SETTINGS.DURATIONS.UNSAVEDCHANGES });
            if (props.onChange) {
                props.onChange(_tempData);
            }
        }
    }
    return (

        <DFCField {...props} value={
            <>
                <span className={`fi fi-${ currentValue?.shortName } mr-2`}></span>
                {currentValue?.name}
            </>
        }>
            <Select isLoading={countries === DATA_VALUES.LOADING} isDisabled={!props.isEditing} isSearchable={false} inputId={"country-select-input"}
                    value={currentValue}
                    components={{ Option: DFCCountryComponent, SingleValue: DFCCountryComponent }}
                    options={countries !== DATA_VALUES.LOADING ? countries : []}
                    placeholder={props.placeholder ?? "Please select.."}
                    onChange={HandleChange}
                  />
        </DFCField>
       
    )
}