import React, {useContext, useEffect, useState} from 'react';
import {DATA_VALUES, FILTER_TYPES} from '../../utils/constants';
import {UtilitiesContext} from '../../providers/utilitiesProvider';
import {Row} from "react-bootstrap";
import {InstitutionCard} from "../../view_forms/institutions/countryOfInstitutions";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {UpdateProfileInstructions} from "../profile/updateProfileInstructions";
import {DFCFilter} from "../../components/dfc/dfcFilter";

export const InstitutionsView = (props) => {
    const {setDynamicViewTitleHTML} = useContext(UtilitiesContext);

    useEffect(() => {
        setDynamicViewTitleHTML("Universities and Colleges");
    }, [setDynamicViewTitleHTML]);

    const countryOptions = [{label: "USA", value: "United States"}, {label: "Canada", value: "Canada"}, {label: "UK", value: "United Kingdom"}]

    const filterCategories =
        [
            {
                // need identifier and prefix expression
                name: "Institution",
                filterType: FILTER_TYPES.SEARCH,
                expression: "data.Name.Trim().ToLower().Contains({$value})"
            },
            {
                // need identifier and prefix expression
                name: "Country",
                filterType: FILTER_TYPES.SELECT,
                options: countryOptions,
                expression: `data.Address.Country.ToLower().Contains({$value})`
            }
        ]

    return (
        <ResponsiveContainerComponent className={"pt-3 pb-5"}>

            <div className={""}>
                <h6 className={"badge badge-warning p-2"}>
                    <UpdateProfileInstructions/>

                </h6>
            </div>

                <DFCFilter
                    title={"Search Institutions"}
                    getDataUrl={"/institution/index?include=Photos"}
                    categories={filterCategories}
                    content={InstitutionsFilteredView}
                    takePerPage={20}
                />
        </ResponsiveContainerComponent>
    )
}

export const InstitutionsFilteredView = (props) =>{
    return (
        <Row className={"pt-2 "}>
            {
                props.data !== DATA_VALUES.NULL && props.data !== DATA_VALUES.LOADING &&
                props.data?.map((institution, index) =>
                    <InstitutionCard key={institution.id} institution={institution}/>
                )

            }
        </Row>
    )
}
