import React, { useEffect, useState } from 'react';
import { DFCField } from './dfcField';
import _ from 'lodash'
import toast from 'react-hot-toast';
import { InfoToastContent } from '../../../utils/helpers';
import {DATA_VALUES, FIELDTYPES, TOAST_SETTINGS} from '../../../utils/constants';
import {Form} from "react-bootstrap";

export const DFCCheckbox = (props) =>
{
    const [currentValue, setCurrentValue] = useState(DATA_VALUES.NULL);

    useEffect(() => {
        if(props.data !== DATA_VALUES.LOADING)
        {
            let _value = props.accessor ? _.get( props.data, props.accessor ) : ( props.defaultValue ?? "" );
            if(props.reverse)
                _value = !_value;
            setCurrentValue(_value);
        }
    }, [props.data]);
    const HandleChange = ( new_value ) =>
    {
        if ( new_value )
        {
            let _tempData = { ...props.data };
            _.set( _tempData, props.accessor, props.reverse?!new_value.target.checked:new_value.target.checked );
            props.setData( _tempData );
            toast( TOAST_SETTINGS.MESSAGES.UNSAVEDCHANGES, { id: TOAST_SETTINGS.IDS.UNSAVEDCHANGES, duration: TOAST_SETTINGS.DURATIONS.UNSAVEDCHANGES } );
        }
    }


    return (
        <DFCField {...props} value={currentValue?"Yes":"No"}>
            <Form.Check disabled={!props.isEditing} onChange={HandleChange}  checked={currentValue}/>
        </DFCField>
    );
}