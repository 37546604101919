import React from 'react';
import {Col, Row} from "react-bootstrap";
import {DFCText} from "../../components/dfc/field/dfcText";
import {FIELDTYPES} from "../../utils/constants";
import {DFCDateTime} from "../../components/dfc/field/dfcDateTime";

export const ApplicationMessageForm = (props) => {

    return (
            <>
                <Col xs={12} >
                    <DFCText title={"Subject"} accessor={`name`} required
                             data={props.data} setData={props.setData}
                             isEditing={props.isEditing} />
                </Col>

                <Col xs={12} >
                    <DFCText title={"Message"} accessor={`details`} required
                             data={props.data} setData={props.setData} type={FIELDTYPES.TEXTAREA}
                             isEditing={props.isEditing} />
                </Col>

                <Col xs={12}>
                    <DFCDateTime  title={"Sent On"} accessor={`createdAt`}
                                  data={props.data}/>
                </Col>

            </>
    )
}