import {DFCText} from "../../components/dfc/field/dfcText";
import {useContext} from "react";
import {Col} from "react-bootstrap";
import {AuthorizationContext} from "../../providers/authorizationProvider";
import {AWSCONSTANTS, VALID_VALUES} from "../../utils/constants";
import Tippy from "@tippyjs/react";
import {DFCCheckbox} from "../../components/dfc/field/dfcCheckbox";

export const InstitutionProgramRequirementForm = (props) => {
    const {GetUserAttribute} = useContext(AuthorizationContext);
    const IsAdmin = GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILETYPE) === VALID_VALUES.PROFILETYPES.ADMIN;

    const _lineHeight="2vh";

    return (
        <>
            <Col xs={12} md={6} lg={4} className={"py-0 my-0"} style={{lineHeight:"2.6vh"}}>
                <DFCText accessor={`name`} required={true} placeholder={"ex: GPA Requirement"}
                         data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField/>
            </Col>

            <Col xs={12} md={6} lg={4} className={"py-0 my-0"} style={{lineHeight:_lineHeight}}>
                <DFCText accessor={`details`} required={true}
                         placeholder={"ex: Please upload your Transcript showing your latest GPA"}
                         data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField/>
            </Col>

            <Col xs={4} className={""} style={{lineHeight:_lineHeight}}>
                {  IsAdmin && props.isEditable &&
                    <DFCCheckbox title={"Completed"} accessor={"completed"} data={props.data} setData={props.setData} isEditing={props.isEditing}/>
                }
                {
                    !IsAdmin && props.data?.completed &&
                    <Tippy content={"This requirement has been marked complete by Applystart Admins"}>
                        <i className={"bi bi-check-square-fill text-success"}></i>
                    </Tippy>
                }
            </Col>
        </>
    );
}