import React, {useContext, useEffect} from "react";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {Link} from "react-router-dom";
import {Row} from "react-bootstrap";
import {DFCFilter, joinerOptions} from "../../components/dfc/dfcFilter";
import {DATA_VALUES, FILTER_TYPES} from "../../utils/constants";
import {AdminApplicantsForm} from "../../view_forms/admin/adminApplicantsForm";
import {DFCForm} from "../../components/dfc/dfcForm";
export const AdminApplicantsView = () => {
    const { setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    useEffect(() => {
        setDynamicViewTitleHTML("Applicant List");
    }, []);

    const filterCategories =
        [
            {
                // need identifier and prefix expression
                name: "Name",
                filterType: FILTER_TYPES.SEARCH,
                expression: "data.Name.Trim().ToLower().Contains({$value})"
            },
            {
                // need identifier and prefix expression
                name: "Applicant ID",
                filterType: FILTER_TYPES.SEARCH,
                expression: "data.displayIndex=={$value}"
            },
            {
                // need identifier and prefix expression
                name: "Email",
                filterType: FILTER_TYPES.SEARCH,
                expression: "data.PreferredEmail.Trim().ToLower().Contains({$value})"
            }
        ]

    return (
        <ResponsiveContainerComponent>

            <Row>
                <Link to={"/admin/create-influencer"} className={"btn btn-secondary m-auto"}>Create Influencer</Link>
            </Row>
            <hr/>

            <ResponsiveContainerComponent>
                <DFCFilter
                    title={"Search Applicants"}
                    getDataUrl={"/applicant/index"}
                    categories={filterCategories}
                    defaultQueries={{'data.IsSnapshot == {$value}':{expression:`data.IsSnapshot == False`, joiner: joinerOptions.and}}}
                    content={ApplicantsFilteredView}
                    takePerPage={20}
                />
            </ResponsiveContainerComponent>
        </ResponsiveContainerComponent>
    )
}
const ApplicantsFilteredView = (props) =>{

    return (
        <Row>
            {
                props.data !== DATA_VALUES.NULL && props.data !== DATA_VALUES.LOADING &&
                props.data?.map((applicant, index) =>
                        <DFCForm className={"w-100"} data={applicant}
                                 formContent={AdminApplicantsForm}
                                 title={`Applicant ID ${applicant.displayIndex}`}/>
                )

            }
        </Row>
    )
}