import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {DFCList} from "../../components/dfc/dfcList";
import {ApplicantFamilyMemberForm} from "../../view_forms/applicant/applicantFamilyMemberForm";
import {ROUTE_VALUES} from "../../configs";



export const ApplicantFamilyTab = (props) => {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <DFCList required={true} title={"Family Members"}
                             isEditable={props.isEditable} isEditing={props.isEditing} editWithModal={false}
                             addInstructions={"You may upload attachments to this item after saving the document details"}
                             headerContent={(dProps) => <h6>{dProps.data?.relationship}</h6>}
                             formContent={ApplicantFamilyMemberForm} sortFunction={(a,b) => a.relationship.toUpperCase()<b.relationship.toUpperCase()?-1:1}
                             getListDataUrl={`/applicant/list/${props.applicantData.id}/FamilyMembers`}
                             deleteDataUrl={`/applicant/list/${props.applicantData.id}/FamilyMembers`}
                             editDataUrl={`/applicant/list/${props.applicantData.id}/FamilyMembers`}
                             addDataUrl={`/applicant/list/${props.applicantData.id}/FamilyMembers`}
                    />
                </Col>
            </Row>
        </>
    )
}