import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash'
import { VALID_VALUES } from '../../utils/constants';
import { AuthorizationContext } from '../../providers/authorizationProvider';
import { GetFormattedString } from '../../utils/helpers';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export const ApplicationsForm = (props) =>
{
    const { GetUserAttribute } = useContext( AuthorizationContext );
    const [ profileType ] = useState( GetUserAttribute( "custom:profile_type" ) );


    return (
        <Row className={"p-2 font-weight-bold w-100"}>
            <Col xs={12} md={6}>
                <Link to={`/applications/${props.data?.id}`} className={"text-secondary"}>
                    {profileType === VALID_VALUES.PROFILETYPES.APPLICANT && props.data?.program?.institution?.name}
                    {profileType === VALID_VALUES.PROFILETYPES.ADMIN &&
                        props.data?.applicantSnapshot?.name +" - "+props.data?.program?.institution?.name}
                </Link>
            </Col>
            <Col xs={12} md={6}>
                <div className={"btn btn-info disabled w-100 p-1"}>
                    {GetFormattedString( props.data?.statusHistory?.sort( ( x, y ) => new Date( y.createdAt ) - new Date( x.createdAt ) )[ 0 ]?.status )}
                </div>
            </Col>

            <Col xs={12} md={6}>
                <div className={""}>
                    {props.data?.program?.name}
                </div>
            </Col>
            <Col xs={12} md={6}>
                <div className={"mt-2 text-muted text-center"}>
                    Last Updated: {new Date( props.data?.updatedAt + "Z" )?.toLocaleString() ?? ""}
                </div>
            </Col>
        </Row>
    );
}