import React from 'react';
import {Container} from "react-bootstrap";

export const ResponsiveContainerComponent = (props)=>{
    return (

        <Container fluid={"xl"} className={" pt-3 "+props.className}>
            {props.children}
        </Container>

    )
}