import React, {useContext, useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {DFCSelect} from "../../components/dfc/field/dfcSelect";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {DFCPerson} from "../../components/dfc/presets/dfcPerson";
import {DFCCheckbox} from "../../components/dfc/field/dfcCheckbox";

export const ApplicantFamilyMemberForm  = (props)=>{
    const {GetDataFromServer} = useContext(UtilitiesContext);


    const [familyMemberTypeEnumOptions, setFamilyMemberTypeEnumOptions] = useState([]);
    const [relationshipEnumOptions, setRelationshipEnumOptions] = useState();

    useEffect(() => {
        if(props.isEditing){
            GetDataFromServer('/system/enumchoices/Relationship', setRelationshipEnumOptions);
            GetDataFromServer('/system/enumchoices/FamilyMemberType', setFamilyMemberTypeEnumOptions);
        }
    }, []);

    const _colSizeMd = 5;

    return (
       <>
           <Col xs={12} md={_colSizeMd}>
               <DFCSelect isEditing={props.isEditing}
                          accessor={`type`} title={"Type"}
                          required
                          data={props.data} setData={props.setData} options={familyMemberTypeEnumOptions} />
           </Col>

           <Col xs={12} md={_colSizeMd}>
               <DFCSelect isEditing={props.isAdding}
                          accessor={`relationship`} title={"Relationship"}
                          required
                          data={props.data} setData={props.setData} options={relationshipEnumOptions} />
            </Col>

            <Col xs={12} md={2}>
                <DFCCheckbox isEditing={props.isEditing}
                    accessor={`person.deceased`} title={"Late?"}
                    
                    data={props.data} setData={props.setData} />
            </Col>

           <hr/>

           <Col xs={12} >
                <DFCPerson data={props.data} setData={props.setData} accessor={"person"} isEditing={props.isEditing}/>
           </Col>
       </>
    )
}