import React, { useEffect, useState } from 'react';
import { DFCField } from './dfcField';
import _ from 'lodash'
import toast from 'react-hot-toast';
import { InfoToastContent } from '../../../utils/helpers';
import {DATA_VALUES, FIELDTYPES, TOAST_SETTINGS} from '../../../utils/constants';
import {Form} from "react-bootstrap";

export const DFCText = (props) =>
{
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        if(props.data !== DATA_VALUES.LOADING && currentValue === "")
            setCurrentValue(((props.accessor && _.get( props.data, props.accessor ))?? props.defaultValue)??"");
    }, [props.data, props.accessor, props.defaultValue]);
    const HandleChange = ( new_value ) =>
    {
        if ( new_value )
        {
            let _tempData = { ...props.data };
            _.set( _tempData, props.accessor, new_value.target.value );
            setCurrentValue(new_value.target.value);
            props.setData( _tempData );
            if(props.onChange)
                props.onChange();
            if (!props.noToast)
                toast( TOAST_SETTINGS.MESSAGES.UNSAVEDCHANGES, { id: TOAST_SETTINGS.IDS.UNSAVEDCHANGES, duration: TOAST_SETTINGS.DURATIONS.UNSAVEDCHANGES } );
        }
    }


    return (
        <DFCField {...props} value={currentValue}>
            <Form.Control  as={props.type === FIELDTYPES.TEXTAREA?"textarea":"input"} className={props.className}
                          placeholder={( props.placeholder ?? (props.type===FIELDTYPES.EMAIL?"abc.def@domain.com":"Please type..") )}
                          disabled={!props.isEditing} onChange={HandleChange}
                          value={currentValue}/>
        </DFCField>
    );
}