import React, { useContext, useEffect } from 'react';
import {Card} from "react-bootstrap";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {APP_DOMAIN, AWS_CONFIGS, HOST_CONFIGS} from "../../configs";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";

export const AuthenticationTemplate = (props) =>
{
    const { setDynamicViewTitleHTML } = useContext( UtilitiesContext );

    const AUTHENTICATION_URI =
        `${ HOST_CONFIGS.AUTH_HOST }/login?client_id=${ AWS_CONFIGS.COGNITO_CLIENTID }&response_type=token&scope=${ AWS_CONFIGS.COGNITO_SCOPES }&redirect_uri=${ APP_DOMAIN }${ AWS_CONFIGS.COGNITO_LOGINRESULT_URL }&state=${ props.path }`

    useEffect( () =>
    {
        setDynamicViewTitleHTML( "Welcome to Applystart" );
    });

    return (
        <ResponsiveContainerComponent className={""}>
            <div className={"mb-4"}>
                <Card bg={"white"} className={"m-auto w-75 w-md-35"}>
                    <Card.Header className={"text-center border-bottom pt-3"}>
                        <img src={require( "../../graphics/logo/applystart-logo-black-svg-width.svg" ).default} className="img-fluid w-75"  alt="applystart-logo"/>
                        <hr className={"my-2"}/>
                        <h2 className={"display-5"}>{props.title}</h2>

                    </Card.Header>

                    <Card.Body className={"text-center"}>
                        {props.children}
                    </Card.Body>
                </Card>
            </div>
        </ResponsiveContainerComponent>
    )
}