import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import toast from 'react-hot-toast';
import { PreloaderComponent } from '../components/preloaderComponent';
import {  COOKIE_KEYS, DATA_VALUES, TOAST_SETTINGS } from '../utils/constants';
import axios from 'axios';
import {AWS_CONFIGS, HOST_CONFIGS, ROUTE_VALUES} from '../configs';
import { AppendServerBaseUrl, ResolveJSON } from '../utils/helpers';

export const AuthorizationContext = createContext();

export const AuthorizationProvider = ( props ) =>
{
    const TOKEN_USER_URL = "/system/tokenuser";
    const logoutUri = `${ HOST_CONFIGS.AUTH_HOST }/logout?client_id=${ AWS_CONFIGS.COGNITO_CLIENTID }&logout_uri=${ HOST_CONFIGS.APP_DOMAIN }${AWS_CONFIGS.COGNITO_LOGOUT_URL}`
    //access_token
    const [ cookies, setCookie, removeCookie ] = useCookies( [ COOKIE_KEYS.access_token ] );

    const [ authorizationPending, setAuthorizationPending ] = useState( true );
    const [ currentUser, setCurrentUser ] = useState( DATA_VALUES.NULL_USER );

    const GetTokenUser = useCallback( ( awsCognitoAccessToken ) =>
    {
        if(currentUser !== DATA_VALUES.NULL_USER)
            return;

        let _url = AppendServerBaseUrl( TOKEN_USER_URL );
        toast.loading( 'authenticating', { id: TOAST_SETTINGS.IDS.AUTHORIZATION } );
        setCurrentUser(DATA_VALUES.LOADING);
        //    make axios call
        axios.get( _url, { headers: { "Authorization": awsCognitoAccessToken }, timeout: 20000 } )
            .then( ( res ) =>
            {
                if ( res.data )
                {
                    var _resolvedAwsUser = ResolveJSON( res.data?.data );
                    setCurrentUser( _resolvedAwsUser );
                    setCookie( "access_token", awsCognitoAccessToken, { path: ROUTE_VALUES.APP_HOMEPAGE } );
                    setAuthorizationPending( false );
                    toast.success( "authenticated", { id: TOAST_SETTINGS.IDS.AUTHORIZATION } );
                } else
                {
                    setAuthorizationPending( false );
                    setCurrentUser( { status: "unexpected response from server" } );
                    InternalLogout();
                    toast.error( "authentication error",{id: TOAST_SETTINGS.IDS.AUTHORIZATION} );
                    setCurrentUser(DATA_VALUES.NULL_USER);
                }
            } )
            .catch( ( err ) =>
            {
                setAuthorizationPending( false );
                setCurrentUser( { status: "unsuccessful signin attempt - " + err.message } );
                InternalLogout();
                toast.error( "authentication error",{id: TOAST_SETTINGS.IDS.AUTHORIZATION}  );
                setCurrentUser(DATA_VALUES.NULL_USER);

            } )
    }, [ setCookie ] );

    const _CheckForCookie = useCallback( () =>
    {
        if ( GetTokenUser && cookies[ COOKIE_KEYS.access_token ] != null && currentUser === DATA_VALUES.NULL_USER )
        {
            GetTokenUser( cookies[ COOKIE_KEYS.access_token ] );
        } else
        {
            setAuthorizationPending( false )
        };
    }, [ cookies, currentUser, GetTokenUser ] );

    const GetUserAttribute = useCallback( ( name ) =>
    {
        if ( currentUser === DATA_VALUES.NULL_USER  )
            return null;
        return currentUser?.attributes?.find( ( x ) => x.name === name )?.value;
    }, [ currentUser ] );

    const InternalLogout =  (silently = false) =>
    {
        removeCookie( COOKIE_KEYS.access_token,{ path: ROUTE_VALUES.APP_HOMEPAGE } );
        setCurrentUser( DATA_VALUES.NULL_USER );
        if ( !silently )
            toast.success( 'you are signed out!', { id: TOAST_SETTINGS.IDS.AUTHORIZATION } );
    };

    useEffect( () =>
    {
        authorizationPending ?
            toast.loading( 'authorizing..', { id: TOAST_SETTINGS.IDS.AUTHORIZATION } ) : toast.dismiss( TOAST_SETTINGS.IDS.AUTHORIZATION );
    }, [ authorizationPending ] );

    useEffect( () =>
    {
        _CheckForCookie();

    }, [  _CheckForCookie ] );


    const returnee = { access_token: cookies[ COOKIE_KEYS.access_token ], currentUser, GetUserAttribute, GetTokenUser, setCookie, InternalLogout, logoutUri };
    return (
        <AuthorizationContext.Provider value={returnee}>
            {authorizationPending ? <PreloaderComponent/> : props.children}
        </AuthorizationContext.Provider>
    );
}