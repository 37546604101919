import React from 'react';
import {Col, Row} from "react-bootstrap";
import {DFCList} from "../../components/dfc/dfcList";
import {ApplicantLanguageForm} from "../../view_forms/applicant/applicantLanguageForm";
export const ApplicantLanguagesTab = (props) => {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <DFCList title={"Languages"} required={true} instructions={"At least one language is required"}
                             isEditable={props.isEditable} hideBody={true}
                             getListDataUrl={`/applicant/list/${props.applicantData.id}/ApplicantLanguages`}
                             deleteDataUrl={`/applicant/list/${props.applicantData.id}/ApplicantLanguages`}
                             addDataUrl={`/applicant/list/${props.applicantData.id}/ApplicantLanguages`}
                             headerContent={(lProps) => <h6>{_.get(lProps.data, "language.name")}</h6>}
                             formContent={ApplicantLanguageForm} sortFunction={(a,b)=>a.language?.name.toUpperCase()<b.language?.name.toUpperCase()?-1:1}
                    />
                </Col>

            </Row>
        </>
    )
}