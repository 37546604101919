import React, {useEffect, useState, useContext, useRef} from "react";
import {DFCField} from "./dfcField";
import _ from 'lodash'
import toast from 'react-hot-toast';
import {DATA_VALUES, TOAST_SETTINGS,} from "../../../utils/constants";

export const DFCDateTime = (props) => {
    const [currentValue, setCurrentValue] = useState("");
    const dateRef = useRef();

    useEffect(() => {
        let rawUTCValue = props.accessor ? (_.get(props.data, props.accessor) + "Z") : props.utcValue;
        if (!rawUTCValue)
            return;

        var dateFromRawUtc = new Date(rawUTCValue);
        let _tempValue = null;
        if (props.allowTime) {
            _tempValue = dateFromRawUtc.toLocaleString("sv-SE")
        } else {
            _tempValue = dateFromRawUtc.toLocaleDateString("sv-SE", {

                year: "numeric",
                month: "numeric",
                day: "numeric",
            })
        }
        setCurrentValue(_tempValue);
        if(dateRef.current && dateRef.current.value == "")
            dateRef.current.value = _tempValue;
    }, [props.data, props.allowTime]);


    const HandleChange = (e) => {
        var new_value = e.target.value;
        if (new_value) {
            let _tempData = {...props.data};

            //if allow time, convert it to UTC
            if (props.allowTime) {
                new_value = new Date(new_value).toISOString().replace("Z", "");
            }
            else new_value = new Date(new_value+"T00:00:00").toISOString().replace("Z", "")

            _.set(_tempData, props.accessor, new_value);
            props.setData(_tempData);
            toast(TOAST_SETTINGS.MESSAGES.UNSAVEDCHANGES, {
                id: TOAST_SETTINGS.IDS.UNSAVEDCHANGES,
                duration: TOAST_SETTINGS.DURATIONS.UNSAVEDCHANGES
            });
        }
    }

    return (
        <DFCField {...props} value={new Date(currentValue)?.toDateString()}>
            <input className="form-control" type={props.allowTime ? "datetime-local" : "date"} onChange={HandleChange}
                   disabled={!props.isEditing} ref={dateRef}
            />
        </DFCField>


    )
}