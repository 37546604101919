import React from 'react';
import {Link} from 'react-router-dom';
import {DFCField} from '../dfc/field/dfcField';

export const LinkButtonComponent = (props) => {
    return (

        <DFCField renderChildren>
            {
                props.internal ?
                    <Link to={props.href}>
                        <div className={"btn-sm p-2 m-1 btn btn-" + (props.variant ?? "primary ") + " " + props.className }>
                            <i className={"bi-" + props.bsIconClassName + ""}/>
                            {props.title}
                        </div>
                    </Link>

                    :
                    <a className={"btn-sm rounded p-2 m-1 btn-" + (props.variant ?? "primary ") + " " + props.className }
                       href={props.href}>
                        {props.bsIconClassName && <i className={"bi-" + props.bsIconClassName + " mx-1"}/> }
                        {props.title}
                    </a>
            }
        </DFCField>

    );
}