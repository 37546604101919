import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";

export const ApplicantPaymentInstructionsContent = ({applicationData})=>{
    const [cost, setCost] = useState();


    useEffect(() => {
        let fee = 0;
        let conversionRate = 90;

        if (applicationData.program.applicationFee.includes("US$")) {
            parseFloat(applicationData.program.applicationFee.replace("US$", ""));
            conversionRate = 108;
        }
        else if (applicationData.program.applicationFee.includes("$")) {
            fee = parseFloat(applicationData.program.applicationFee.replace("$", ""));
        }

        setCost(fee * conversionRate);
    }, []);

    return (
        <Card>
            <Card.Header>
                <h5>Payment Instructions</h5>
            </Card.Header>
            <Card.Body>
                <div style={{ fontSize: "16px" }}>
                    <p> Follow the instructions below to submit your application fee and proceed with your application. </p>

                    <p> <b><i>1)</i></b> Ensure you are using a bKash personal account to send the application fee. </p>

                    <p> <b><i>2)</i></b> Use "Send Money" and enter the bKash account number: <mark>01862361386</mark> </p>

                    <p> <b><i>3)</i></b> Enter the amount of the application fee as specified in the application guidelines. </p>

                    <p> <b><i>4)</i></b> Enter your full name and application ID as the reference for the payment. </p>

                    <p> Upon successful payment, your application will be forwarded for assessment within three (3) working days. </p>

                    <p> Application fees are non-refundable, except in the case where Applystart is unable to submit your application
                        to the respective institution. In such circumstances, a refund will be processed by Applystart within 10 working days
                        to your original payment method. </p>

                    <p> We strongly recommend that you retain the transaction ID and confirmation message as proof of payment. </p>

                    <p> If you encounter any issues or have questions regarding the payment process, please reach out to our support team
                        at team@applystart.com. </p>

                    <p> Thank you for choosing Applystart for your application process. </p>

                    <p> This program has an application fee of: <mark>{cost} Tk</mark></p>
                </div>
            </Card.Body>
        </Card>
    )
}