import React from 'react';
import {Col, Row} from "react-bootstrap";
import {DFCText} from "../../components/dfc/field/dfcText";

export const AdminCountriesFormContent = (props) => {
    if (!props.data)
        return <div>No data</div>

    return (
        <Col xs={12}>
            <Row>
                <Col xs={1} className={"align-self-center"}>
                    <span className={`fi fi-${ props.data.shortName } ml-2`}></span>
                </Col>
                <Col xs={3}>
                    <DFCText title={"Name"} accessor={"name"}  data={props.data} setData={props.setData} />
                </Col>
                <Col xs={2}>
                    <DFCText className={"text-truncate"} title={"Flag"} accessor={"shortName"}  data={props.data} setData={props.setData} />
                </Col>
                <Col xs={2}>
                    <DFCText title={"Currency"} accessor={"currencyName"}  data={props.data} setData={props.setData} />
                </Col>
                <Col xs={2}>
                    <DFCText className={"text-truncate"} title={"Curr. Symbol"} accessor={"currencySymbol"}  data={props.data} setData={props.setData} />
                </Col>
                <Col xs={2}>
                    <DFCText className={"text-truncate"} title={"Phone Code"} accessor={"phoneCode"}  data={props.data} setData={props.setData} />
                </Col>
            </Row>
        </Col>
    );
}