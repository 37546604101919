import {Col} from "react-bootstrap";
import React, {useState} from "react";
import {FIELDTYPES} from "../../utils/constants";
import {DFCText} from "../../components/dfc/field/dfcText";
import {DFCPhone} from "../../components/dfc/presets/dfcPhone";
import {DFCSelect} from "../../components/dfc/field/dfcSelect";

export const InstitutionOthersForm = (props) => {

    const rankingListNameOptions= [
        {label: "US News", value: "US News"},
        {label: "QS Ranking", value: "QS Ranking"},
        {label: "Maclean's University Rankings", value: "Maclean's University Rankings"}
    ];

    return (
        <>
            <Col xs={12}>
                <DFCText required={true} title={"Rank"} accessor={`ranking.position`} isNotField={true}
                         data={props.data} setData={props.setData} isEditing={props.isEditing}/>
            </Col>

            <Col xs={12}>
                <DFCSelect required={true} title={"Ranking Source"} accessor={`ranking.accordingToList`}  isNotField={true}
                         data={props.data} setData={props.setData} isEditing={props.isEditing} options={rankingListNameOptions}/>
            </Col>

        </>
    )
}