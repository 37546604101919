import React, {useContext, useEffect, useState} from 'react';
import {DFCField} from '../field/dfcField';
import _ from 'lodash'
import {DATA_VALUES, FIELDTYPES} from '../../../utils/constants';
import {UtilitiesContext} from "../../../providers/utilitiesProvider";
import {Col,  Row} from "react-bootstrap";
import {DFCSelect} from "../field/dfcSelect";
import {DFCText} from "../field/dfcText";
import {DFCCountrySelect} from "../field/dfcCountrySelect";

export const DFCPhone = (props) => {
    const {GetDataFromServer,countries} = useContext(UtilitiesContext);
    const [currentValue, setCurrentValue] = useState(DATA_VALUES.NULL);
    const [currentValueCountry, setCurrentValueCountry] = useState(DATA_VALUES.NULL);
    const [phoneTypeEnumOptions, setPhoneTypeEnumOptions] = useState([]);
    const defaultPhone = {country: "bd", number: "", type: "mobile"}

    useEffect(() => {
        if(props.isEditing)
            GetDataFromServer('/system/enumchoices/PhoneType', setPhoneTypeEnumOptions);
    }, [props.isEditing]);

    useEffect(() => {
        if (props.data !== DATA_VALUES.LOADING)
        {
            setCurrentValue(props.accessor ? _.get(props.data, props.accessor) : (props.defaultValue ?? defaultPhone));
            if (countries !== DATA_VALUES.LOADING)
                setCurrentValueCountry(countries?.find(x => x.id === _.get(props.data, props.accessor)?.country));
        }
    }, [props.data, countries]);

    return (
        <DFCField {...props} type={FIELDTYPES.PHONE} value={<>
            <span className={`fi fi-${currentValueCountry?.shortName} mr-2`}></span>
            +{currentValueCountry?.phoneCode} | {currentValue?.number} | {currentValue?.type}
        </>}>

            <Row className={"px-3"}>
                <Col className={"mx-0 px-0"}>
                    <DFCCountrySelect isEditing={props.isEditing} optionLabelAccessor={"phoneCode"} optionValueAccessor={"id"}
                                      data={props.data} setData={props.setData} accessor={props.accessor + ".country"}/>
                </Col>
                <Col className={"mx-0  px-0"}> <DFCText isEditing={props.isEditing} className={"rounded-0"}
                                                        data={props.data} setData={props.setData}
                                                        accessor={props.accessor + ".number"}/></Col>
                <Col className={"mx-0 px-0"}>
                    <DFCSelect
                        options={phoneTypeEnumOptions}
                        isEditing={props.isEditing}
                        data={props.data} setData={props.setData} accessor={props.accessor + ".type"}
                /></Col>
            </Row>

        </DFCField>
    );
}