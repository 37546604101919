import React, {useEffect, useState } from 'react';
import { DFCText } from '../../components/dfc/field/dfcText';
import {FIELDTYPES} from '../../utils/constants';
import {Col, FormCheck} from "react-bootstrap";
import {DFCPhone} from "../../components/dfc/presets/dfcPhone";
import {DFCAddress} from "../../components/dfc/presets/dfcAddress";

export const ApplicantContactInformationForm = ( props ) =>
{
    const [mailingAddressSameAsResidentAddress, setMailingAddressSameAsResidentAddress] = useState(false);
    const _colSizeMd = props.isEditing?6:6;
    const CopyResidentialAddressToMailingAddressIfChecked = (e)=>{
        if(!e.target.checked)
        {
            setMailingAddressSameAsResidentAddress(false);
            return;
        }

        const _residentAddress = props.data.residentAddress;
        const _mailingAddress = {
            country: _residentAddress.country,
            googlePlaceId: _residentAddress.googlePlaceId,
            subLocality: _residentAddress.subLocality,
            locality: _residentAddress.locality,
            areaLevel1: _residentAddress.areaLevel1,
            areaLevel2: _residentAddress.areaLevel2,
            street1: _residentAddress.street1,
            street2: _residentAddress.street2,
            postCode: _residentAddress.postCode,
        }

        const _tempData = {...props.data};
        _tempData.mailingAddress = _mailingAddress;
        props.setData(_tempData);
        setMailingAddressSameAsResidentAddress(true);
    }

    const CheckIfMailingAddressSameAsResidentialAddress = ()=>{
        const _mailingAddress = props.data.mailingAddress;
        const _residentAddress = props.data.residentAddress;
        if(
            _residentAddress.country == _mailingAddress.country &&
            _residentAddress.googlePlaceId == _mailingAddress.googlePlaceId &&
            _residentAddress.subLocality == _mailingAddress.subLocality &&
            _residentAddress.locality == _mailingAddress.locality &&
            _residentAddress.areaLevel1 == _mailingAddress.areaLevel1 &&
            _residentAddress.areaLevel2 == _mailingAddress.areaLevel2 &&
            _residentAddress.street1 == _mailingAddress.street1 &&
            _residentAddress.street2 == _mailingAddress.street2 &&
            _residentAddress.postCode == _mailingAddress.postCode
        ) setMailingAddressSameAsResidentAddress(true);
        else setMailingAddressSameAsResidentAddress(false);
    }

    useEffect(() => {
      CheckIfMailingAddressSameAsResidentialAddress();
    }, []);
    return (
        <>
            <Col xs={12} md={_colSizeMd}>
                <DFCText title={"Preferred Email"} instructions={"Your account email address is the primary form of contact"}
                         accessor={"preferredEmail"} type={FIELDTYPES.EMAIL}
                         data={props.data} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCText title={"Alternate Email"} instructions={"Your account email address is the primary form of contact"}
                         accessor={"alternateEmail"} type={FIELDTYPES.EMAIL} isEditing={props.isEditing}
                         data={props.data} setData={props.setData}/>
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCPhone title={"Preferred Phone"} instructions={"Your account email address is the primary form of contact"}
                          accessor={"preferredPhone"} required isEditing={props.isEditing}
                          data={props.data} setData={props.setData} />
            </Col>

            <Col xs={12} md={_colSizeMd}>
                <DFCPhone title={"Alternate Phone"} instructions={"Your account email address is the primary form of contact"}
                          accessor={"alternatePhone"} isEditing={props.isEditing}
                          data={props.data} setData={props.setData} />
            </Col>

            <Col xs={12}>
                <DFCAddress
                    title={"Residential Address"} accessor={"residentAddress"} required isEditing={props.isEditing}
                    data={props.data} setData={props.setData} onChange={CheckIfMailingAddressSameAsResidentialAddress}
                />
            </Col>

            <Col xs={12}>
                <FormCheck>
                    <FormCheck.Input
                        disabled={!props.isEditing}
                        checked={mailingAddressSameAsResidentAddress}
                        onChange={CopyResidentialAddressToMailingAddressIfChecked}
                    />
                    <FormCheck.Label>
                        Mailing address is the same as my residential address
                    </FormCheck.Label>
                </FormCheck>
            </Col>

            {
                !mailingAddressSameAsResidentAddress &&
                <Col xs={12}>
                    <DFCAddress
                        title={"Mailing Address"} accessor={"mailingAddress"} required isEditing={props.isEditing}
                        data={props.data} setData={props.setData}
                    />
                </Col>
            }
        </>
    )
}
