import React, {useContext, useEffect} from "react";
import {LinkButtonComponent} from "../../components/buttons/linkButtonComponent";
import {AuthorizationContext} from "../../providers/authorizationProvider";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {Card} from "react-bootstrap";
import {AuthenticationTemplate} from "./authenticationTemplate";
import { VIEWTITLE_VALUES} from "../../utils/constants";
import {useParams, useHistory} from "react-router-dom";
import {AUTHENTICATION_URI} from "./authenticationSigninPromptView";
import {ROUTE_VALUES} from "../../configs";
import {PreloaderComponent} from "../../components/preloaderComponent";

export const AuthenticationSignupCompleteView = () => {
    const {setDynamicViewTitleHTML} = useContext(UtilitiesContext);
    const {InternalLogout} = useContext(AuthorizationContext);
    const params = useParams();
    const browserHistory = useHistory();

    useEffect(() => {
        if(!params.email)
            browserHistory.push(ROUTE_VALUES.APP_HOMEPAGE);
        setDynamicViewTitleHTML(VIEWTITLE_VALUES.EMPTY);
        InternalLogout();
    }, [InternalLogout])

    if(!params.email)
        return <PreloaderComponent/>
    return (
        <AuthenticationTemplate title={`Your account has been created`}>
            <Card.Text className={"font-weight-bold"}>{params.email}</Card.Text>
            <div className={"text-left p-1 mb-2"}>
                <ol>
                    <li>Please Check Email: Your temporary credentials have been sent to your provided email address. Please Check spam/promotions folder if you do not see it.</li>
                    <li>Sign In: Click below and use the credentials provided to sign in to Applystart.</li>
                </ol>
            </div>
            <LinkButtonComponent bsIconClassName={"unlock-fill mt-3 "} variant={"secondary"} href={AUTHENTICATION_URI+`&state=/profile`} title={"Log In"}/>

        </AuthenticationTemplate>
    );
}