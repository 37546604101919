import {Col} from "react-bootstrap";
import {DFCDocuments} from "../../components/dfc/dfcDocuments";
import {FIELDTYPES} from "../../utils/constants";
import {DFCText} from "../../components/dfc/field/dfcText";

export const InstitutionAdditionalInformationForm = (props) => {
    return (
        <>
            <Col xs={12}>
                <DFCText required={true} title={"Name"} accessor={`name`}
                         data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField={true}/>
            </Col>

            <Col xs={12}>
                <DFCText required={true} title={"Description"} accessor={`details`} type={FIELDTYPES.TEXTAREA}
                         data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField={true}/>

            </Col>

            <Col xs={12}>
                <DFCText title={"Website"} accessor={`link`} link={true} type={FIELDTYPES.WEBSITE}
                         data={props.data} setData={props.setData} isEditing={props.isEditing} isNotField={true}/>

            </Col>

            {props.data?.id?
                <Col xs={12} >
                    <DFCDocuments title={"Attachments"}
                                  getListDataUrl={`/document/list/AdditionalInformation/${props.data?.id}?type=Public`}
                                  addDataUrl={`/document/list/AdditionalInformation/${props.data?.id}?type=Public`}
                                  deleteDataUrl={`/document/index`}
                                  isEditing={props.isEditing} maxAttachments={2}
                    />
                </Col>:
                <></>}

        </>
    )
}